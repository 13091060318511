import {
  Box,
  Button,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import lang_config from "../../../language";
import React from "react";

const SliderType = ({
  truckTire,
  trailerTire,
  state,
  setState,
  next,
  setNext,
  lang,
  question,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("lg"));
  let entry = 20;
  let key = question[1]["key"].split(".");
  if (key.length === 3) {
    entry = state[key[0]][key[1]][key[2]];
  } else {
    entry = state[key[0]][key[1]];
  }
  let dep_entry = null;
  let dep_key = question[1]["dependence_key"].split(".");
  if (dep_key.length === 3) {
    dep_entry = state[dep_key[0]][dep_key[1]][dep_key[2]];
  } else {
    dep_entry = state[dep_key[0]][dep_key[1]];
  }
  // console.log(question[1]["option_style"], "hello");
  const handleChange = (value) => {
    if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: {
            ...state[key[0]][key[1]],
            [key[2]]: value,
          },
        },
      });
    } else {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: value,
        },
      });
    }
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box style={{ margin: "2rem 0rem" }}>
        <Slider
          name={question[1]["key"]}
          value={entry}
          valueLabelDisplay="on"
          onChange={(e, value) => handleChange(value)}
        />

        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              width: mobile ? "200px" : "100px",
              heigth: mobile ? "200px" : "100px",
            }}
          >
            {question[1]["option_style"] === undefined ? (
              <Typography variant="subtitle1">
                {key[2] === "tire_size_percentage_of_fleet"
                  ? key[1] === "truck" || key[1] === "e_truck"
                    ? [
                        truckTire.steer_1,
                        "Steer",
                        "+",
                        truckTire.drive_1,
                        "Drive",
                      ].join(" ")
                    : trailerTire.tire_1
                  : dep_entry[0]}
              </Typography>
            ) : (
              <img
                style={{ width: "100%" }}
                src={require(`../../../assets/images/${dep_entry[0]}.png`)}
                alt={dep_entry[0]}
              />
            )}

            <Typography
              variant={"subtitle1"}
              color="primary"
              style={{ fontWeight: "bold" }}
              align="center"
            >
              ({entry}%)
            </Typography>
          </Box>
          <Box
            style={{
              width: mobile ? "200px" : "100px",
              heigth: mobile ? "200px" : "100px",
            }}
          >
            {question[1]["option_style"] === undefined ? (
              <Typography variant="subtitle1">
                {" "}
                {key[2] === "tire_size_percentage_of_fleet"
                  ? key[1] === "truck" || key[1] === "e_truck"
                    ? [
                        truckTire.steer_2,
                        "Steer",
                        "+",
                        truckTire.drive_2,
                        "Drive",
                      ].join(" ")
                    : trailerTire.tire_2
                  : dep_entry[1]}
              </Typography>
            ) : (
              <img
                style={{ width: "100%" }}
                src={require(`../../../assets/images/${dep_entry[1]}.png`)}
                alt={dep_entry[0]}
              />
            )}

            <Typography
              variant={"subtitle1"}
              color="primary"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              ({100 - entry}%)
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            margin: "1rem 0rem",
          }}
        >
          <Button
            style={{
              justifyContent: "flex-end",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              setNext(next + 1);
            }}
            data-hook="slider-next-button"
          >
            {lang_config[lang].buttons.next}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SliderType;
