import React from 'react';

import { Box } from '@material-ui/core';
import { theme } from '../../styles/theme';
import en from '../../assets/images/DE.jpg';
import de from '../../assets/images/UK.jpg';
// const LANGUAGES = {
//   en: { name: 'English' },
//   de: { name: 'Deutsch' },
// };

export const flags = [
  {
    id: 1,
    source: de,
    alt: 'UK flag',
    lang: 'en',
  },
  {
    id: 2,
    source: en,
    alt: 'German flag',
    lang: 'de',
  },
];

const LanguageSwitcher = ({ lang, setLanguage }) => {
  return (
    <Box style={{ display: 'flex', gap: theme.spacing(1), marginLeft: 50 }}>
      {flags.map((flag) => (
        <div
          style={{
            paddingBottom: 1,
            borderBottom: flag.lang === lang && '5px solid #67A425',
            opacity: flag.lang === lang ? 1 : 0.5,
            cursor: 'pointer',
          }}
          onClick={() => {
            setLanguage(flag?.lang);
          }}
        >
          <img
            src={flag?.source}
            alt={flag?.alt}
            style={{
              width: '2.5vw',
              height: '1.7vw',
            }}
          />
        </div>
      ))}
    </Box>
  );
};

export default LanguageSwitcher;
