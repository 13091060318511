import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { tire_sizes } from "../../../utils/const";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const TruckDropdown = ({
  state,
  setState,
  next,
  setNext,
  lang,
  e_truck,
  setE_TruckTire,
  e_bus,
  setE_BusTire,
  busTire,
  setBusTire,
  question,
  isScroll = true,
  truckTire,
  setTruckTire,
  trailerTire,
  setTrailerTire,
}) => {
  const classes = useStyles();

  let key = question[1]["key"].split(".");

  const handleE_BusTireChange = (e) => {
    setE_BusTire({
      ...e_bus,
      [e.target.name]: e.target.value,
    });
  };

  const handleE_TruckTireChange = (e) => {
    setE_TruckTire({
      ...e_truck,
      [e.target.name]: e.target.value,
    });
  };
  const handleBusTireChange = (e) => {
    setBusTire({
      ...busTire,
      [e.target.name]: e.target.value,
    });
  };

  const handleTruckTireChange = (e) => {
    setTruckTire({
      ...truckTire,
      [e.target.name]: e.target.value,
    });
  };

  const handleTrailerTireChange = (e) => {
    setTrailerTire({
      ...trailerTire,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box
      style={{
        margin: "2rem 0rem",
        maxHeight: isScroll ? 350 : "auto",
        overflowY: isScroll ? "scroll" : "hidden",

        scrollBehavior: "smooth",
        overflowX: "hidden",
      }}
    >
      <>
        {key[1] === "truck" ? (
          <Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 1</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                  data-testid="steer_1_dropdown"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={truckTire.steer_1}
                    onChange={handleTruckTireChange}
                    label="Steer"
                    name="steer_1"
                    inputProps={{
                      "data-hook": "steer_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={truckTire.drive_1}
                    onChange={handleTruckTireChange}
                    label="Drive"
                    name="drive_1"
                    inputProps={{
                      "data-hook": "drive_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 2</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={truckTire.steer_2}
                    onChange={handleTruckTireChange}
                    label="Steer"
                    name="steer_2"
                    inputProps={{
                      "data-hook": "steer_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={truckTire.drive_2}
                    onChange={handleTruckTireChange}
                    label="Drive"
                    name="drive_2"
                    inputProps={{
                      "data-hook": "drive_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        ) : key[1] === "bus" ? (
          <Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 1</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={busTire.steer_1}
                    onChange={handleBusTireChange}
                    label="Steer"
                    name="steer_1"
                    inputProps={{
                      "data-hook": "steer_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={busTire.drive_1}
                    onChange={handleBusTireChange}
                    label="Drive"
                    name="drive_1"
                    inputProps={{
                      "data-hook": "drive_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 2</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={busTire.steer_2}
                    onChange={handleBusTireChange}
                    label="Steer"
                    name="steer_2"
                    inputProps={{
                      "data-hook": "steer_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={busTire.drive_2}
                    onChange={handleBusTireChange}
                    label="Drive"
                    name="drive_2"
                    inputProps={{
                      "data-hook": "drive_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        ) : key[1] === "e_truck" ? (
          <Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 1</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                  data-testid="steer_1_dropdown"
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={e_truck.steer_1}
                    onChange={handleE_TruckTireChange}
                    label="Steer"
                    name="steer_1"
                    inputProps={{
                      "data-hook": "steer_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={e_truck.drive_1}
                    onChange={handleE_TruckTireChange}
                    label="Drive"
                    name="drive_1"
                    inputProps={{
                      "data-hook": "drive_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 2</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={e_truck.steer_2}
                    onChange={handleE_TruckTireChange}
                    label="Steer"
                    name="steer_2"
                    inputProps={{
                      "data-hook": "steer_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={e_truck.drive_2}
                    onChange={handleE_TruckTireChange}
                    label="Drive"
                    name="drive_2"
                    inputProps={{
                      "data-hook": "drive_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        ) : key[1] === "e_bus" ? (
          <Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 1</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={e_bus.steer_1}
                    onChange={handleE_BusTireChange}
                    label="Steer"
                    name="steer_1"
                    inputProps={{
                      "data-hook": "steer_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={e_bus.drive_1}
                    onChange={handleE_BusTireChange}
                    label="Drive"
                    name="drive_1"
                    inputProps={{
                      "data-hook": "drive_1_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">Preferred Tire Pair 2</Typography>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Steer
                  </InputLabel>
                  <Select
                    native
                    value={e_bus.steer_2}
                    onChange={handleE_BusTireChange}
                    label="Steer"
                    name="steer_2"
                    inputProps={{
                      "data-hook": "steer_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Drive
                  </InputLabel>
                  <Select
                    native
                    value={e_bus.drive_2}
                    onChange={handleE_BusTireChange}
                    label="Drive"
                    name="drive_2"
                    inputProps={{
                      "data-hook": "drive_2_select",
                    }}
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes.map((size, i) => (
                      <option value={size}>{size}</option>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box>
              <Box display="flex" flexDirection={"row"}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Preferred Tire 1
                  </InputLabel>
                  <Select
                    native
                    value={trailerTire.tire_1}
                    onChange={(e) => {
                      handleTrailerTireChange(e);
                    }}
                    label="Preferred Tire 1"
                    name="tire_1"
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes
                      .filter((x) => x !== trailerTire.tire_2)
                      .map((size, i) => (
                        <option value={size}>{size}</option>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Preferred Tire 2
                  </InputLabel>
                  <Select
                    native
                    value={trailerTire.tire_2}
                    onChange={(e) => {
                      handleTrailerTireChange(e);
                    }}
                    label="Preferred Tire 2"
                    name="tire_2"
                  >
                    <option aria-label="None" value="" />
                    {tire_sizes
                      .filter((x) => x !== trailerTire.tire_1)
                      .map((size, i) => (
                        <option value={size}>{size}</option>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        )}
      </>
    </Box>
  );
};

export default TruckDropdown;
