import { Box, Button, Grid, useTheme } from "@material-ui/core";
import React from "react";

const SingleSelect = ({ state, setState, next, setNext, lang, question }) => {
  const theme = useTheme();

  let entry = null;
  let key = question[1]["key"].split(".");

  if (key.length === 3) {
    entry = state[key[0]][key[1]][key[2]];
  } else {
    entry = state[key[0]][key[1]];
  }

  console.log(question, "+++++++");
  const handleChange = (value) => {
    if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: {
            ...state[key[0]][key[1]],
            [key[2]]: value,
          },
        },
      });
    } else {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: value,
        },
      });
    }

    setNext(next + 1);
  };
  return (
    <Box style={{ margin: "2rem 0rem" }}>
      {question[1]["option_style"] !== undefined ? (
        <Grid container spacing={3}>
          {question[1]["options"][lang].map((_item, j) => (
            <Grid item lg={4}>
              <Button
                style={{
                  backgroundColor:
                    entry === question[1]["options"]["en"][j]
                      ? "#fff"
                      : "#F4F4F4",
                  border:
                    entry === question[1]["options"]["en"][j]
                      ? `2px solid ${theme.palette.primary.main}`
                      : "none",
                  textTransform: "unset",
                  opacity:
                    (entry === question[1]["options"]["en"][j]) === false
                      ? 1
                      : (entry === question[1]["options"]["en"][j]) === false &&
                        entry.length === 2
                      ? 0.5
                      : 1,
                }}
                disableElevation
                color="primary"
                data-hook={`single-select-${j}`}
                onClick={() => handleChange(question[1]["options"]["en"][j])}
              >
                <Box>
                  {question[1]["options"]["en"][j] === "Other" ? (
                    question[1]["options"]["en"][j]
                  ) : (
                    <img
                      style={{ maxWidth: 200 }}
                      src={require(`../../../assets/images/${question[1]["options"]["en"][j]}.png`)}
                      alt=""
                    />
                  )}
                </Box>
              </Button>
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {question[1]["options"][lang].map((_item, j) => (
            <Box style={{ margin: "0.5rem 0rem" }}>
              <Button
                variant={
                  entry === question[1]["options"]["en"][j]
                    ? "outlined"
                    : "contained"
                }
                disableElevation
                color="primary"
                onClick={() => handleChange(question[1]["options"]["en"][j])}
                style={{ textTransform: "unset" }}
                data-hook={`single-select-${j}`}
              >
                {_item}
              </Button>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default SingleSelect;
