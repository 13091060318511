import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Button,
  useTheme,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useFormik } from "formik";
import {
  formValidations,
  passwordComplexityConfig,
  signupvalidationSchema,
} from "../../../utils/string_utils";
import {
  checkUserExist,
  IntegrateFleetData,
  validatePayload,
} from "../../../services/fleet_service";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { SignUpUser } from "../../../services/fleet_service";
import PasswordComplexityDepictor from "../../PasswordComplexityDepictor";
import lang_config from "../../../language";
const SignUpForm = ({
  busTire,
  e_truck,
  e_bus,
  truckTire,
  trailerTire,
  state,
  next,
  setNext,
  setSuccess,
  operate,
  orgData,
  lang,
  setNotification,
  calcEstimate,
}) => {
  const [disable, setDisable] = useState(false);
  const [showPassword, setShow] = useState(false);
  const [passwordComplexityState, setPasswordComplexityState] = useState(null);
  const [errorMessageKeyword, setErrorMessageKeyword] = useState({
    email: null,
  });
  const [successMessagesKeyword, setSuccessMessagesKeyword] = useState({
    email: null,
  });
  let general_configs_map = new Map();
  for (let i = 0; i < operate.length; i++) {
    let temp = {
      no_of_units: state["general_configs"][operate[i]].no_of_units,
      km_driven_per_year:
        state["general_configs"][operate[i]].km_driven_per_year,

      axle_type_1: state["general_configs"][operate[i]].axle_types[0],
      axle_1_perc:
        state["general_configs"][operate[i]].axle_type_percentage_of_fleet,
      axle_type_2: state["general_configs"][operate[i]].axle_types[1],
      axle_2_perc:
        100 -
        state["general_configs"][operate[i]].axle_type_percentage_of_fleet,
      tire_type_1:
        operate[i] === "truck"
          ? [truckTire.steer_1, "Steer", "+", truckTire.drive_1, "Drive"].join(
              " "
            )
          : operate[i] === "e_truck"
          ? [e_truck.steer_1, "Steer", "+", e_truck.drive_1, "Drive"].join(" ")
          : operate[i] === "bus"
          ? [busTire.steer_1, "Steer", "+", busTire.drive_1, "Drive"].join(" ")
          : operate[i] === "e_bus"
          ? [e_bus.steer_1, "Steer", "+", e_bus.drive_1, "Drive"].join(" ")
          : trailerTire.tire_1,
      tire_1_perc:
        state["general_configs"][operate[i]].tire_size_percentage_of_fleet,
      tire_type_2:
        operate[i] === "truck"
          ? [truckTire.steer_2, "Steer", "+", truckTire.drive_2, "Drive"].join(
              " "
            )
          : operate[i] === "e_truck"
          ? [e_truck.steer_2, "Steer", "+", e_truck.drive_2, "Drive"].join(" ")
          : operate[i] === "bus"
          ? [busTire.steer_2, "Steer", "+", busTire.drive_2, "Drive"].join(" ")
          : operate[i] === "e_bus"
          ? [e_bus.steer_2, "Steer", "+", e_bus.drive_2, "Drive"].join(" ")
          : trailerTire.tire_2,
      tire_2_perc:
        100 -
        state["general_configs"][operate[i]].tire_size_percentage_of_fleet,
    };

    general_configs_map.set(operate[i].toUpperCase(), temp);
  }
  // console.log(Object.fromEntries(general_configs_map));
  const theme = useTheme();
  let tireManagement = state.tire_management;
  const payload = {
    organization_data: orgData,
    tire_management: {
      is_hot_retread: tireManagement.is_hot_retread,
      is_cold_retread: tireManagement.is_cold_retread,
      is_de_minimis: tireManagement.is_de_minimis,
      preferred_tire_brand_1: tireManagement.preferred_tire_brands[0],
      preferred_tire_brand_2: tireManagement.preferred_tire_brands[1],
      preferred_tire_line_1: tireManagement.preferred_tire_lines[0],
      preferred_tire_line_2: tireManagement.preferred_tire_lines[1],
      how_service_today: tireManagement.how_service_today,
      where_service_today: tireManagement.where_service_today,
      preferred_service_provider: tireManagement.preferred_service_provider,
      perc_retread: tireManagement.perc_retread,
    },
    general_configs: Object.fromEntries(general_configs_map),
  };
  const handleOnborardingDataIntegration = async (access_token) => {
    try {
      const fleet_res = await IntegrateFleetData(payload, access_token);
      if (fleet_res.status === 200) {
        calcEstimate();
        setNext(next + 1);
        setSuccess(true);
      } else {
        setNotification({
          show: true,
          type: "error",
          message: fleet_res.data,
        });
      }
    } catch (err) {
      console.error(err);
      setNotification({
        show: true,
        type: "error",
        message: "An Error has occured , Try Again",
      });
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",

      locale: lang,
    },
    validationSchema: signupvalidationSchema,
    onSubmit: async (values) => {
      try {
        const validation_res = await validatePayload(payload);
        if (validation_res.status === 200) {
          setDisable(true);
          const res = await SignUpUser(values);
          if (res.status === 200 || res.status === 201) {
            setNotification({
              show: true,
              type: "success",
              message: "User Successfully Created",
            });
            setDisable(false);
            // setUserData(res.data.associations);
            handleOnborardingDataIntegration(res.data.access_token);
          } else {
            setNotification({
              show: true,
              type: "error",
              message: res.data.message,
            });
            setDisable(false);
          }
        } else {
          console.error(validation_res.data.message);
          setNotification({
            show: true,
            type: "error",
            message: validation_res.data.message,
          });
          // window.location.reload();
        }
        // formik.resetForm();
      } catch (err) {
        console.error(err);
        setNotification({
          show: true,
          type: "error",
          message: "An Error has occured , Try Again",
        });
      }
    },
  });
  const handleClickShowPassword = () => {
    setShow(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBlur = (e) => {
    if (e.target.name === "email") {
      const email = formik.values.email;
      if (formValidations.email.test(email)) {
        checkEmailTaken(email);
      } else {
        setErrorMessageKeyword({
          ...errorMessageKeyword,
          email: "invalid",
        });
      }
    }
  };

  const checkEmailTaken = async (email) => {
    const res = await checkUserExist(email);
    if (res.status === 200 && res?.data?.resp?.customer_id) {
      setErrorMessageKeyword({
        ...errorMessageKeyword,
        email: "emailTaken",
      });
    } else {
      setSuccessMessagesKeyword({
        ...successMessagesKeyword,
        email: "emailAvailable",
      });
    }
  };

  useEffect(() => {
    const password = formik.values.password;
    const passwordComplexityConfigKeys = Object.keys(passwordComplexityConfig);
    let temp = {};
    if (password) {
      passwordComplexityConfigKeys.forEach((key) => {
        if (key === "length") {
          password.length >= passwordComplexityConfig[key]["condition"]
            ? (temp[key] = true)
            : (temp[key] = false);
        } else {
          passwordComplexityConfig[key]["condition"].test(password)
            ? (temp[key] = true)
            : (temp[key] = false);
        }
      });

      setPasswordComplexityState({ ...temp });
    } else if (!password && passwordComplexityState) {
      passwordComplexityConfigKeys.forEach((key) => {
        temp[key] = false;
      });
      setPasswordComplexityState({ ...temp });
    }
    /*eslint-disable-next-line*/
  }, [formik.values.password]);

  useEffect(() => {
    setErrorMessageKeyword({ ...errorMessageKeyword, email: null });
    setSuccessMessagesKeyword({ ...successMessagesKeyword, email: null });
    /*eslint-disable-next-line*/
  }, [formik.values.email]);

  return (
    <Box
      style={{
        maxWidth: 700,
        width: "100%",
      }}
      mx="1rem"
    >
      <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <Box position="relative">
          <TextField
            margin={"normal"}
            label={lang_config[lang].form.fields.email}
            name={"email"}
            id={"email"}
            required
            type={"email"}
            variant={"outlined"}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              (formik.touched.email && Boolean(formik.errors.email)) ||
              errorMessageKeyword.email
            }
            helperText={
              errorMessageKeyword.email
                ? lang_config[lang].form.email[errorMessageKeyword.email]
                : formik.touched.email && formik.errors.email
            }
            fullWidth
            onBlur={handleBlur}
          />
          {successMessagesKeyword.email && (
            <Box
              display="flex"
              color="green"
              alignItems={"center"}
              ml={0.5}
              position={"absolute"}
              right={-150}
              top={30}
            >
              <CheckCircleIcon />
              <Box ml={1}>
                {lang_config[lang].form.email[successMessagesKeyword.email]}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            marginTop: "1rem",
            marginBottom: "0.5rem",
            position: "relative",
          }}
        >
          <FormControl
            fullWidth
            variant="outlined"
            required
            error={formik.touched.password && Boolean(formik.errors.password)}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {lang_config[lang].form.fields.password}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              margin={"normal"}
              label={"Password"}
              name={"password"}
              required
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              variant={"outlined"}
              value={formik.values.password}
              onChange={formik.handleChange}
              fullWidth
            />
            <FormHelperText id="outlined-weight-helper-text">
              {formik.touched.password && formik.errors.password}
            </FormHelperText>
          </FormControl>
          {passwordComplexityState && (
            <PasswordComplexityDepictor
              password={passwordComplexityState}
              lang={lang}
            />
          )}
        </Box>
        <TextField
          margin={"normal"}
          label={lang_config[lang].form.fields.first_name}
          name={"first_name"}
          id={"first_name"}
          required
          type={"first_name"}
          variant={"outlined"}
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.touched.first_name && Boolean(formik.errors.first_name)}
          helperText={formik.touched.first_name && formik.errors.first_name}
          fullWidth
        />
        <TextField
          margin={"normal"}
          label={lang_config[lang].form.fields.last_name}
          name={"last_name"}
          id={"last_name"}
          required
          type={"last_name"}
          variant={"outlined"}
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.touched.last_name && Boolean(formik.errors.last_name)}
          helperText={formik.touched.last_name && formik.errors.last_name}
          fullWidth
        />
        {/* <TextField
          margin={"normal"}
          select
          SelectProps={{
            native: true,
          }}
          label={lang_config[lang].form.fields.position}
          name={"position"}
          id={"position"}
          required
          variant={"outlined"}
          value={formik.values.position}
          onChange={formik.handleChange}
          error={formik.touched.position && Boolean(formik.errors.position)}
          helperText={formik.touched.position && formik.errors.position}
          fullWidth
        >
          {position_arr.map((item, i) => (
            <option key={i} value={item}>
              <Typography variant="h6">
                {item[0].toUpperCase() + item.slice(1)}
              </Typography>
            </option>
          ))}
        </TextField> */}
        <Box my="0.5rem">
          <Typography
            variant="subtitle2"
            gutterBottom
            align="center"
            color="textSecondary"
          >
            {lang === "en"
              ? "By Clicking on Sign-Up, you accept our"
              : "Sie Akzeptieren unsere"}
            &nbsp;
            <a
              href="/terms-conditions"
              target="_blank"
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
            >
              {lang === "en" ? "Terms&Conditions" : "AGB "}
              &nbsp;
            </a>
            {lang === "en" ? "and" : "und"}
            <a
              href="/privacy-policy"
              target="_blank"
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
              }}
            >
              &nbsp; {lang === "en" ? "PrivacyPolicy" : "Datenschutzerklärung"}
              &nbsp;
            </a>
            {lang !== "en" && "in dem Sie Sign-up anclicken."}
          </Typography>
        </Box>
        <Box
          width="100%"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            color={"primary"}
            type={"submit"}
            variant={"contained"}
            disabled={disable}
            disableElevation
            style={{ textTransform: "unset" }}
            data-hook="sign-up-submit-button"
          >
            <Typography variant="h6" align="center">
              {lang_config[lang].buttons.sign_up}
            </Typography>
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default SignUpForm;
// const position_arr = [
//   "controlling",
//   "accounting",
//   "it",
//   "sustainability",
//   "sales",
//   "purchase",
//   "management",
// ];
