import React from "react";
import { Box, TextField } from "@material-ui/core";
import lang_config from "../../../language";
const IntegerType = ({ state, setState, next, setNext, lang, question }) => {
  let entry = null;
  let key = question[1]["key"].split(".");
  if (key.length === 3) {
    entry = state[key[0]][key[1]][key[2]];
  } else {
    entry = state[key[0]][key[1]];
  }
  const handleChange = (e) => {
    if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: {
            ...state[key[0]][key[1]],
            [key[2]]: e.target.value,
          },
        },
      });
    } else {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: e.target.value,
        },
      });
    }
  };
  return (
    <Box style={{ margin: "2rem 0rem" }}>
      <TextField
        autoFocus
        name={question[1]["key"]}
        type="number"
        helperText={lang_config[lang].integer_type.sub_text}
        required
        id="standard-basic"
        value={entry}
        onChange={handleChange}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            setNext(next + 1);
          }
        }}
        fullWidth
      />
    </Box>
  );
};

export default IntegerType;
