import React from "react";
import { Box, Button } from "@material-ui/core";

const BooleanType = ({ state, setState, next, setNext, lang, question }) => {
  let entry = null;
  let key = question[1]["key"].split(".");
  if (key.length === 3) {
    entry = state[key[0]][key[1]][key[2]];
  } else {
    entry = state[key[0]][key[1]];
  }
  const handleChange = (value) => {
    if (["trailer", "truck", "e_truck"].includes(key[1])) {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: {
            ...state[key[0]][key[1]],
            [key[2]]: value,
          },
        },
      });
    } else {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: value,
        },
      });
    }
    setNext(next + 1);
  };
  return (
    <Box style={{ margin: "2rem 0rem" }}>
      <Box style={{ margin: "0.5rem 0rem" }}>
        <Button
          variant={entry === true ? "outlined" : "contained"}
          disableElevation
          color="primary"
          onClick={() => handleChange(true)}
          style={{ textTransform: "unset" }}
          data-hook="confirm-yes"
        >
          {lang === "en" ? "Yes" : "Ja"}
        </Button>
      </Box>
      <Box style={{ margin: "0.5rem 0rem" }}>
        <Button
          variant={entry === false ? "outlined" : "contained"}
          disableElevation
          color="primary"
          onClick={() => handleChange(false)}
          style={{ textTransform: "unset" }}
        >
          {lang === "en" ? "No" : "Nein"}
        </Button>
      </Box>
    </Box>
  );
};

export default BooleanType;
