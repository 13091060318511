import React, { useState, useEffect } from "react";

import FormArea from "./components/FormArea";
import "./styles/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TermsConditions from "./components/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { Login } from "./services/fleet_service";
import Maintenance from "./components/Maintenance";
function App() {
  const [isMaintenance, setMaintenance] = useState(false);
  const checkBackendAvailablity = async () => {
    const testLoginPayload = {
      email: process.env.REACT_APP_TEST_EMAIL,
      password: process.env.REACT_APP_TEST_PASSWORD,
    };
    try {
      const res = await Login(testLoginPayload);
      if (res !== undefined) {
        if (res?.status === 200 || res?.status === 201) {
          setMaintenance(false);
        } else {
          setMaintenance(true);
        }
      } else {
        setMaintenance(true);
      }
    } catch (err) {
      setMaintenance(true);
    }
  };
  useEffect(() => {
    checkBackendAvailablity();
    // eslint-disable-next-line
  }, []);
  if (isMaintenance) return <Maintenance />;
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <>
              {/* <Navbar ans={ans} operate={operate} /> */}
              <FormArea />
            </>
          </Route>

          <Route path="/terms-conditions">
            <>
              {/* <Navbar ans={ans} operate={operate} /> */}
              <TermsConditions />
            </>
          </Route>

          <Route path="/privacy-policy">
            <>
              {/* <Navbar ans={ans} operate={operate} /> */}
              <PrivacyPolicy />
            </>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
