import * as yup from "yup";

export const signupvalidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .trim()
    .min(8, "Atleast 8 Characters")
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      "Must contain atleast one lowercase, uppercase ,number & symbol each"
    )
    .required("Password is required"),
  first_name: yup
    .string("Enter your first")
    .min(3, "Atleast 3 Characters")
    .required("Required"),
  last_name: yup
    .string("Enter your first")
    .min(3, "Atleast 3 Characters")
    .required("Required"),

  locale: yup.string().required("Required"),
});

export const orgValidationSchema = yup.object({
  name: yup
    .string("Enter Company Name")
    .min(5, "Min 5 charaters needed")
    .required("Company Name is required"),
  no_of_yards: yup
    .number("Enter no of yards")
    .min(1, "Min 1 charaters needed")
    .required("No of yards is required"),
  zip_code: yup
    .number("Enter your zip code")
    .min(1000)
    .max(999999)
    .required("Required"),
  city: yup.string().min(2).max(20).required().required("Required"),
  country: yup.string().min(2).max(20).required("Required"),
});

export const passwordComplexityConfig = {
  number: { condition: /\d/ },
  specialCharacter: { condition: /\W/ },
  lowercase: { condition: /[a-z]/ },
  uppercase: { condition: /[A-Z]/ },
  length: { condition: 8 },
};

export const formValidations = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
};
