import {
  Box,
  Button,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";

const SingleSliderType = ({
  state,
  setState,
  next,
  setNext,
  lang,
  question,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("lg"));
  let entry = 20;
  let key = question[1]["key"].split(".");

  if (key.length === 3) {
    entry = state[key[0]][key[1]][key[2]];
  } else {
    entry = state[key[0]][key[1]];
  }

  const handleChange = (value) => {
    if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: {
            ...state[key[0]][key[1]],
            [key[2]]: value,
          },
        },
      });
    } else {
      setState({
        ...state,
        [key[0]]: {
          ...state[key[0]],
          [key[1]]: value,
        },
      });
    }
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box style={{ margin: "2rem 0rem" }}>
        <Slider
          name={question[1]["key"]}
          value={entry}
          valueLabelDisplay="on"
          data-hook="slider-data-hook"
          onChange={(e, value) => handleChange(value)}
        />

        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              width: mobile ? "200px" : "100px",
              heigth: mobile ? "200px" : "100px",
            }}
          >
            <Typography
              variant="subtitle1"
              align="left"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              ( {question[1]["min_limit"]} )
            </Typography>
          </Box>
          <Box
            style={{
              width: mobile ? "200px" : "100px",
              heigth: mobile ? "200px" : "100px",
            }}
          >
            <Typography
              variant="subtitle1"
              align="right"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              ( {question[1]["max_limit"]} )
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            margin: "1rem 0rem",
          }}
        >
          <Button
            style={{
              justifyContent: "flex-end",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              setNext(next + 1);
            }}
            data-hook="slider-next-button"
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SingleSliderType;
