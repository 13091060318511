import React from "react";
import { Box, Button, Grid, useTheme } from "@material-ui/core";

const DoubleSelect = ({
  state,
  setState,
  next,
  setNext,
  lang,
  question,
  isScroll = true,
}) => {
  const theme = useTheme();

  let entry = null;
  let key = question[1]["key"].split(".");
  if (key.length === 3) {
    entry = state[key[0]][key[1]][key[2]];
  } else {
    entry = state[key[0]][key[1]];
  }

  const handleChange = (value) => {
    if (entry.includes(value) === false) {
      if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: {
              ...state[key[0]][key[1]],
              [key[2]]: [...entry, value],
            },
          },
        });
      } else {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: [...entry, value],
          },
        });
      }

      if (entry.length === 1) {
        setNext(next + 1);
      }
    } else {
      if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: {
              ...state[key[0]][key[1]],
              [key[2]]: entry.filter((item) => item !== value),
            },
          },
        });
      } else {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: entry.filter((item) => item !== value),
          },
        });
      }
    }
  };
  return (
    <Box
      style={{
        margin: "2rem 0rem",
        maxHeight: isScroll ? 350 : "auto",
        overflowY: isScroll ? "scroll" : "hidden",

        scrollBehavior: "smooth",
        overflowX: "hidden",
      }}
    >
      {question[1]["option_style"] !== undefined ? (
        <Grid container spacing={3}>
          {question[1]["options"][lang]
            .filter((x) => {
              if (
                key[2] === "axle_types" &&
                (key[1] === "bus" || key[1] === "e_bus")
              ) {
                if (x === "4x2" || x === "6x2" || x === "6x4" || x === "8x4") {
                  return x;
                }
                return false;
              }
              return x;
            })
            .map((_item, j) => (
              <Grid item lg={4}>
                <Button
                  data-hook={`double-select-question-button-${j}`}
                  disabled={
                    entry.length === 2 &&
                    entry.includes(question[1]["options"]["en"][j]) === false
                      ? true
                      : false
                  }
                  style={{
                    backgroundColor: entry.includes(
                      question[1]["options"]["en"][j]
                    )
                      ? "#fff"
                      : "#F4F4F4",
                    border: entry.includes(question[1]["options"]["en"][j])
                      ? `2px solid ${theme.palette.primary.main}`
                      : "none",
                    textTransform: "unset",
                    opacity:
                      entry.includes(question[1]["options"]["en"][j]) ===
                        false && entry.length === 0
                        ? 1
                        : entry.includes(question[1]["options"]["en"][j]) ===
                            false && entry.length === 2
                        ? 0.5
                        : 1,
                  }}
                  onClick={() => handleChange(_item)}
                >
                  <Box style={{ objectFit: "contain" }}>
                    <img
                      style={{ maxWidth: 200 }}
                      src={require(`../../../assets/images/${_item}.png`)}
                      alt=""
                    />
                  </Box>
                </Button>
              </Grid>
            ))}
        </Grid>
      ) : (
        <>
          {question[1]["options"][lang].map((_item, j) => (
            <Box style={{ margin: "0.5rem 0rem" }}>
              <Button
                variant={
                  entry.includes(question[1]["options"]["en"][j])
                    ? "outlined"
                    : "contained"
                }
                data-hook={`double-select-question-button-${j}`}
                disableElevation
                disabled={
                  entry.length === 2 &&
                  entry.includes(question[1]["options"]["en"][j]) === false
                    ? true
                    : false
                }
                color="primary"
                onClick={() => handleChange(question[1]["options"]["en"][j])}
                style={{ textTransform: "unset" }}
              >
                {_item}
              </Button>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default DoubleSelect;
