export const de = {
  form: {
    email: {
      invalid: "Ungültige E-Mail",
      emailTaken: "Bereits erstelltes Konto mit der gleichen E-Mail",
      emailAvailable: "E-Mail verfügbar",
    },
    fields: {
      email: "Email",
      first_name: "Vorname",
      last_name: "Nachname",
      password: "Kennwort",
      position: "Position",
    },
  },
  integer_type: {
    sub_text: "Drücken Sie Enter um fortzufahren",
  },
  buttons: {
    next: "Nächste Seite",
    sign_up: "Registrieren",
    go_to_sign_in: "Zum Anmelden",
  },
  links: {
    terms_and_conditions: "AGB",
    privacy_policy: "Datenschutzerklärung",
    and: "und",
  },
  left_panel: {
    current_tire_management: "Current Tire Management",
    org_info: "Organization Info",
    user_info: "User Info",
  },
  passwordComplexity: {
    number: "Mindestens eine Nummer",
    specialCharacter: "Mindestens ein Sonderzeichen",
    lowercase: "Mindestens ein Kleinbuchstabe",
    uppercase: "Mindestens ein Großbuchstabe",
    length: "Mindestens 8 Zeichen",
  },
  maintencePage: {
    title: "Unter Wartung",
    description:
      "Unsere Techniker führen derzeit planmäßige Wartungsarbeiten am Armaturenbrett durch.",
    apology: "Entschuldigung für die Unannehmlichkeiten.",
  },
};
