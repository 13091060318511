import { Box, Container } from '@material-ui/core';


const TermsConditions = () => {
  return (
    
     <Box style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
      <Container>
        <div style={{ margin: '2rem 0rem' }}>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              textAlign: 'center',
              background: 'none',
            }}
          >
            <strong>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                Allgemeine Geschä
              </span>
            </strong>
            <strong>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                f
              </span>
            </strong>
            <strong>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                tsbedingungen
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              textAlign: 'center',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              Version 1.0 (gü
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              l
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              tig ab: 13.05.2022)
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              Allgemeine Geschä
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              f
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              tsbedingungen der&nbsp;
            </span>
          </p>

          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              Allgemeine Geschä
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              f
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              tsbedingungen der&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              CO2OPT GmbH <br />
              &nbsp;c/o St. Annenufer 2<br />
              &nbsp;20457 Hamburg&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              - im Folgenden „CO2OPT“ genannt&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              Diese Allgemeinen Geschä
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              f
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              tsbedingungen (im Folgenden als „AGB“ bezeichnet) stellen eine
              nicht bezahlte Rahmenvereinbarung zwischen CO2OPT und seinem
              Geschä
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              f
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              tspartner (im Folgenden als "Kunde" bezeichnet) fü
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              r
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              &nbsp;die Nutzung von CO2OPT-Kundenkonten, Abrechnungskonten und
              Leistungen dar (CO2OPT und der Kunde zusammen im Folgenden als
              "Parteien" bezeichnet).&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              Mit einem CO2OPT-Konto kann der Kunde bestimmte kostenpflichtige
              und kostenlose Leistungen nutzen (im Folgenden als
              "Software-Leistung" oder "Leistung" bezeichnet).&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              A. Anwendungsbereich&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die AGB gelten fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;alle von CO2OPT angebotenen Dienstleistungen und sind
                untrennbarer Bestandteil des Vertrags zwischen CO2OPT und dem
                Kunden (im Folgenden als „Vertrag“ bezeichnet) sowie die
                vertragliche Grundlage fü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;alle vom Kunden genutzten Leistungen, es sei denn etwas
                anderes ist zwischen den Parteien vereinbart. Ebenso finden sie
                Anwendung auf Einzelverträ
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                e und Vereinbarungen zur Anpassung (Customization), zum
                Proof-of-Concept und fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;andere Leistungen zwischen den Parteien.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die AGB gelten ausschließlich. Allgemeine Geschäftsbedingungen
                des Kunden werden nicht Bestandteil des Vertrags zwischen CO2OPT
                und dem Kunden, es sei denn, CO2OPT stimmt diesen mit einer
                Erklärung in Textform ausdrücklich zu.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              B. Vertragsabschluss&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Nutzung der von CO2OPT angebotenen Leistungen beruht auf
                einem gesonderten Vertrag zwischen CO2OPT und dem Kunden. Die
                AGB werden Bestandteil dieses Vertrags, soweit sie keine
                widersprü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                hliche Klausel zum Vertrag enthalten.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Nur die zwischen den Parteien vereinbarten Dokumente, die ausdrü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                klich online oder offline Bestandteil dieses Vertrags werden,
                sind verbindlich. Mü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                dliche Vereinbarungen vor dem Vertragsschluss sind nicht
                rechtsverbindlich und etwaige mü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                dliche Vereinbarungen zwischen den Parteien werden durch den
                Vertrag ersetzt, es sei denn, es wird ausdrü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>c</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                klich vereinbart, dass sie weiterhin verbindlich bleiben. Der
                Vertrag ist in Textform zu erfassen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die Nutzung der CO2OPT Leistungen kontaktiert der Kunde
                CO2OPT ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er die Website. Der Kunde stellt alle relevanten Informationen
                und Dokumente zur Verfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ung, die für die Nutzung der Leistungen erforderlich sind.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Leistungen, die dem Kunden gegenü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>b</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er von Dritten erbracht werden, basieren auf einer gesonderten
                Vereinbarung, die ausschließlich zwischen dem Kunden und dem
                jeweiligen Drittanbieter abgeschlossen wird. CO2OPT ist keine
                Partei dieser Vereinbarung und trä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                t daher keine Pflichten gegenü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                er dem Kunden. Alle Leistungen von CO2OPT richten sich
                ausschließlich an Personen, die das 18. Lebensjahr vollendet
                haben und Unternehmer im Sinne des § 14 Abs. 1 BGB sind.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              C. Leistungsnutzung&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Kosten fu
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ̈
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                r die Leistungen von CO2OPT, die der Kunde abonniert, ergeben
                sich aus den einzelnen Vertragsdokumenten bzw. dem Angebot oder
                Leistungsschein (im Folgenden „Leistungsschein“) der jeweiligen
                Leistung.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Nutzung der Leistungen erfordert eine Registrierung als
                Kunde. Eine Registrierung als Privatperson ist nicht gestattet,
                da die Leistungen nur an gewerblichen Kunden richten, wie im
                Absatz B.6 beschrieben.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Kunde richtet durch das Registrierungsformular ein
                Firmenkonto (im Folgenden als „Konto“ bezeichnet) ein. Mit der
                Zustimmung zu den AGB und der Datenschutzerklärung von CO2OPT
                wird das Konto aktiviert.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Kunde ist verpflichtet, bei der Registrierung korrekte
                Angaben anzugeben und seine Kontaktdaten und sonstigen
                Pflichtangaben, einschließlich der für den Zahlungsverkehr
                erforderlichen Daten, auf dem neuesten Stand zu halten.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Eine natü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                liche Person, die sich im Namen eines Dritten registriert (z. B.
                eines Unternehmens, einer Vereinigung oder einer anderen natü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                lichen oder juristischen Person), kann bei Bedarf eine
                ordnungsgemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                e Vollmacht der juristischen Person vorlegen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er das Konto kann der Kunde, die von CO2OPT angebotenen
                Leistungen erwerben.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Kommunikation zwischen CO2OPT und dem Kunden erfolgt online,
                per Webanwendung, per E-Mail oder telefonisch. Der Kunde hat
                daher seine Kontaktdaten gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Absatz 4 dieses Abschnitts auf dem neuesten Stand zu
                halten und eingehende Nachrichten regelmä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>ß</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ig zu ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                erprüf
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                e
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Kunde hat keinen Anspruch auf Registrierung. CO2OPT behä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t sich das Recht vor, eine Registrierung ohne Begrü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                dung abzulehnen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              D. Leistungsumfang&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Leistungsumfang, die Funktionen und Merkmale der einzelnen
                Leistungen ergeben sich aus dem jeweiligen Leistungsschein der
                angebotenen Leistungen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Durch das Konto hat der Kunde Zugang zu den CO2OPT Leistungen.
                Die Software-Leistungen werden als webbasierter
                Software-as-a-Service Dienst zugestellt. Sie werden ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er einen Browser ausgefü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                rt. Eine lokale Installation ist daher nicht erforderlich.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Durch die Einrichtung eines Kontos und die Ü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ermittlung der Zugangsdaten rä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                u
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                mt CO2OPT dem Kunden ein nicht ausschließliches, nicht ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ertragbares, nicht unterlizenzierbares, zeitlich auf die
                Laufzeit des jeweiligen Vertrags beschrä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ktes Recht ein, die Softwareleistungen im Rahmen der
                Bestimmungen des Vertrags zu nutzen. Der Anwendungsbereich
                umfasst der Europä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                i
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                schen Wirtschaftsraums (EWR) und die Schweiz, es sei denn im
                Angebot ist etwas anderes geregelt.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Bei Erweiterung des Funktionsumfangs bestehender CO2OPT
                Leistungen oder beim Angebot neuer Leistungen wä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rend der Vertragslaufzeit finden die AGB automatisch auf die
                neuen Funktionen oder Leistungen Anwendung.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Qualitä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;der Leistungen hä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                gt von der Qualitä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;der empfangenen Rohdaten ab. Der Kunde ist allein
                verantwortlich fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die bereitgestellten Rohdaten. CO2OPT ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                erprüf
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;oder bewertet die empfangenen Rohdaten im Allgemeinen
                nicht.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT verbessert mit Hilfe von Algorithmen die angebotenen
                Dienste laufend weiter, z. B. durch Analyse der vom Kunden
                bereitgestellten Angaben, Daten und Dokumente (im Folgenden als
                „Kundendaten“ bezeichnet). Der Kunde räumt CO2OPT das Recht ein,
                die Kundendaten zu diesem Zweck zu verwenden.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die von CO2OPT angebotenen Softwareleistungen basieren auf
                Datenerhebungs-, Speicher- und Analysesystemen bzw. Software von
                Drittanbietern.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Um die Daten seiner eigenen Fahrzeugflotte zu verbinden,
                verwendet der Kunde unterschiedliche Übertragungskanäle,
                einschließlich, aber nicht beschrä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                kt auf APIs (Application Programming Interfaces), die von CO2OPT
                zusammen mit den verbundenen Leistungen bestimmter
                Fahrzeughersteller und anderer Drittanbieter zur Verfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                ung gestellt werden.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die ordnungsgemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                e Nutzung von CO2OPT Leistungen kö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                nen somit Software und Leistungen von Drittanbietern
                erforderlich sein (z. B. Analysetools, Datenbanksysteme usw.).
                CO2OPT hat keinen Einfluss auf eventuelle Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                derungen der Leistungen Dritter. Im Fall von Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                derungen durch Dritte kann CO2OPT bestimmte Leistungen mö
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                licherweise nicht im ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lichen Umfang bereitstellen. In solchen Fä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                len wird CO2OPT die erbrachte Leistung unter Berü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ksichtigung der Interessen des Kunden anpassen bzw. ggf. ä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                dern.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;die Leistungen Dritter kö
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                nten bestimmte Gebü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ren erhoben werden, die zwischen dem Drittanbieter und dem
                Kunden zu vereinbaren sind. CO2OPT ist bzw. wird keine Partei
                dieser Vereinbarung.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              E. Pflichten des Kunden&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde ist zur Mitwirkung verpflichtet, soweit es zur
                ordnungsgemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                en Durchfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                rung des Vertrags erforderlich ist. Insbesondere hat der Kunde
                CO2OPT unaufgefordert alle notwendigen Daten zu ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ermitteln, damit CO2OPT die entsprechenden Leistungen erbringen
                kann. Darü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>b</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er hinaus gewä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rleistet der Kunde CO2OPT Zugang zu den oben genannten, fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;die Erbringung von Leistungen notwendigen, Daten, gemä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;den geltenden Gesetzen und den internen Leitlinien des
                Kunden.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde berü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ksichtigt alle Unterlagen und Klarstellungen von CO2OPT und
                wendet sich im Falle eines Missverstä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                dnisses an CO2OPT. Darü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er hinaus prü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                f
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                t der Kunde sorgfä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                tig die Integritä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;und Gü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                tigkeit aller von CO2OPT erhaltenen Dokumente. Bei Fehlern ist
                er verpflichtet, CO2OPT unverzü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                lich zu benachrichtigen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde hat seine Konto- und Anmeldedaten geheim zu halten und
                vor unbefugten Dritten zu schü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                zen. Wenn ein Dritter Zugriff auf die oben genannten Daten oder
                das Konto erhält oder der Verdacht auf einen Datenverstoß
                besteht, muss der Kunde CO2OPT umgehend informieren und die
                Anmeldedaten unverzü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lich ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                dern, sobald er davon Kenntnis erlangt.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                CO2OPT Leistungen dü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                fen nur vom Kunden oder seinen Mitarbeitern genutzt werden. Die
                Nutzung durch oder der Zugang fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Dritte ist nicht gestattet.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde unternimmt alle erforderlichen Maßnahmen, um die
                Qualität der an CO2OPT übermittelten Daten sicherzustellen. Der
                Kunde stellt sicher, dass keine Malware (z. B. Viren) oder
                andere unerwü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                schte Daten oder Programme zusammen mit den erforderlichen Daten
                ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ertragen werden, die fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;den Zweck des Vertrages nicht relevant sind. CO2OPT haftet
                nicht fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Scha
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                d
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                en, die aus einem Verstoß gegen die oben genannte
                Sorgfaltspflicht resultieren.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde stellt sicher, dass die fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die Nutzung der CO2OPT Softwareleistungen erforderlichen
                Systemanforderungen erfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lt sind.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Im Falle eines Mangels hat der Kunde CO2OPT Funktionsprobleme,
                Fehlfunktionen oder Beeinträ
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                htigungen der Software unverzü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lich und so genau wie mö
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                lich zu melden. Unterlä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                s
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                st der Kunde diese Verpflichtung, gilt § 536c BGB.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              F. Nutzungsrechte&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Nutzungsrechte werden gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;Absatz D.3 der AGB gewä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>h</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rt.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT gewä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                rt dem Kunden ein nicht exklusives, nicht ü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ertragbares, nicht unterlizenzierbares Recht, das zeitlich auf
                die jeweilige Vertragslaufzeit begrenzt ist, die
                Softwareleistungen, -dokumente und -informationen im Rahmen der
                Vertragsbedingungen und der AGB zu nutzen. Diese Übertragung von
                Rechten betrifft jedes Nutzungsrecht und jede bekannte und
                unbekannte Art der Nutzung. Bei Beendigung des Vertrages ist der
                Kunde verpflichtet, von der vorgenannten Nutzung umgehend
                abzusehen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Um die angebotenen Leistungen zu erbringen und
                weiterzuentwickeln, sammelt, speichert und verarbeitet CO2OPT
                Anwendungs- und Rohdaten des Kunden gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;den AGB. Die Zustimmung des Kunden erfolgt durch die
                Annahme der AGB.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT hat das ausschließliche Recht, die Ergebnisse und
                Entwicklungen zu verwenden, die sich aus den oben genannten
                Prozessen ergeben, einschließlich Datenerhebung, Verwendung,
                Analyse, Bewertung und Weiterverarbeitung.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Rohdaten, die der Kunde CO2OPT gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;den AGB zur Verfü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ung stellt, bleiben das alleinige Eigentum des Kunden. Mit
                Beendigung des Vertrages hat der Kunde das Recht, die Lö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                chung der Daten zu verlangen. CO2OPT lö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                cht daraufhin die Daten spä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                estens ein (1) Jahr nach Ablauf der Vertragslaufzeit.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Alle schutzfähigen und nicht schutzfähigen Arbeitsergebnisse,
                insbesondere geistiges Eigentum (im Folgenden "IP" genannt),
                Rechte (einschließlich Urheberrechte, gewerbliche Schutzrechte,
                wie Patente und alle IP-bezogenen Nutzungsrechte) an und im
                Zusammenhang mit Werken oder Derivaten davon, Erfindungen und
                Informationen, einschließlich, aber nicht beschränkt auf,
                Algorithmen, Software, Quellcodes, Objektcodes, Materialien,
                Methoden, Werkzeuge, Know-how, Analysen und Produkte,
                Produkt-Updates und Produkt-Upgrades, sowie alle Rechte an und
                fü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;alle Entwicklungen und alle anderen Arbeitsprodukte, die
                aus der Verwendung der Daten des Auftraggebers durch CO2OPT im
                Rahmen des Projekts von CO2OPT generiert, erstellt und/oder
                abgeleitet werden, sind alleiniges Eigentum von CO2OPT. Nur
                CO2OPT hat das Recht, die Arbeitsergebnisse kommerziell zu
                verwerten und anderweitig zu nutzen, einschließlich, aber nicht
                beschränkt auf die Weiterentwicklung aller im Rahmen des
                Vertrags entwickelten Arbeitsergebnisse. Diese Rechte umfassen
                auch das Recht die Arbeitsergebnisse unterzulizenzieren, zu
                verarbeiten und weiterzuentwickeln. Ungeachtet des
                ausschließlichen Rechts von CO2OPT, die Arbeitsergebnisse
                kommerziell zu verwerten und anderweitig zu nutzen, hat der
                Kunde das Recht, diese für interne Zwecke zu nutzen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT ist Hersteller der entsprechenden Datenbanken und
                Softwareleistungen nach § 87a Urheberrechtsgesetz. Rechte an
                geistigem Eigentum, Urheberrechten bzw. Nutzungsrechten in
                anonymer Form verbleiben nach Beendigung dieses Vertrages
                ausschließlich bei CO2OPT. CO2OPT hat das Recht, diese Daten zu
                speichern, zu verwenden, zu analysieren und zu verarbeiten. Ihre
                Derivate bleiben auch nach Vertragsende das alleinige Eigentum
                von CO2OPT.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT hat das Recht, nach eigenem Ermessen die Registrierung
                von Eigentumsrechten wie einem nationalen oder internationalen
                Patent oder einem Gebrauchsmuster fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;eine im Rahmen des Projekts abgeleitete Erfindung zu
                beantragen und die entsprechenden Eigentumsrechte auszuü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                en. Der Kunde hat jedoch kein Recht auf Registrierung von
                Eigentumsrechten oder Registrierung und Verwertung der
                relevanten Arbeitsergebnisse.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Wenn ein Dritter geltend macht, dass die vom Kunden gelieferten
                Daten seine Eigentumsrechte verletzen, einschließlich, aber
                nicht beschränkt auf Urheberrecht, Unterschlagung von Geschä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                f
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                tsgeheimnissen oder anderen Rechten an geistigem Eigentum, hat
                der Kunde CO2OPT unverzü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                lich zu informieren. Auf Verlangen und soweit begrü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                det oder gesetzlich vorgeschrieben, werden die Parteien bei der
                Abwehr oder Beilegung solcher Ansprü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                he angemessen zusammenarbeiten.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde darf die CO2OPT-Software nicht ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                dern. Insbesondere ist der Kunde nicht berechtigt, Funktionen
                mittels "Reverse Engineering" zu untersuchen, zu dekompilieren
                (mit Ausnahme von § 69e UrhG), in die Bestandteilten zu zerlegen
                oder als Grundlage fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die Erstellung eigener Software und Leistungen zu
                verwenden.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              G. Kü
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              n
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              digung&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Nach Annahme der AGB werden CO2OPT Leistungen gemä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;den Bestimmungen dieses Absatzes verfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                bar.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die AGB werden erst dann separat beendet, wenn jede einzelne
                CO2OPT Leistung beendet wurde. Die Kü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                digungsfrist der AGB beträ
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t einen Monat nach dem Ende des Monats, in dem CO2OPT die
                Kündigungserkla
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ̈
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rung fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;die AGB erhalten hat.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Falls der Kunde sein Konto oder die Nutzung der Leistungen
                kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                digt, hat der Kunde keinen Anspruch auf Rü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                kerstattung von Gebü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ren, die der Kunde vor dem Zeitpunkt der Kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                digung fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;die Nutzung der Leistungen gezahlt hat.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Das Recht auf außerordentliche Kündigung bleibt für die
                gesetzlich geregelte Fälle unberührt. Bei Widerspruch des Kunden
                hinsichtlich einer notwendigen technischen Änderung der Dienste
                behält sich CO2OPT das Recht zur außerordentlichen Kündigung
                seiner Dienste vor.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Das Recht auf Kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                digung aus wichtigem Grund bleibt fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;beide Parteien hiervon unberü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                rt. <br />
                &nbsp;Ein wichtiger Grund liegt beispielsweise vor wenn:
              </span>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      color: 'black',
                      fontSize: '15px',
                    }}
                  >
                    Ein wesentlicher Verstoß gegen die Verpflichtungen einer der
                    Parteien, der nicht gemäß dem Einzelvertrag behoben wurde
                    und der es der anderen Partei unmö
                  </span>
                  <span
                    style={{
                      fontFamily: '"Arial",sans-serif',
                      color: 'black',
                      fontSize: '10.0pt',
                    }}
                  >
                    g
                  </span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      color: 'black',
                      fontSize: '15px',
                    }}
                  >
                    lich macht, ihren Verpflichtungen nachzukommen.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      color: 'black',
                      fontSize: '10.0pt',
                    }}
                  >
                    &nbsp;Insolvenzverfahren gegen eine der Parteien oder
                    Ablehnung dieses Verfahrens wegen fehlenden Vermö
                  </span>
                  <span
                    style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
                  >
                    g
                  </span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    ens.&nbsp;
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                In jedem anderen Fall werden die AGB nach den gesetzlichen
                Bestimmungen beendet.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                digung hat schriftlich zu erfolgen; die elektronische Form ist
                ausreichend.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Im Falle eines wiederholten und schwerwiegenden Verstoßes gegen
                die AGB ist CO2OPT berechtigt, den Zugang des Kunden zum Account
                zu sperren und den Vertrag außerordentlich zu kündigen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              H. Zahlungsbedingungen&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Kosten und Gebü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                ren fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;einzelne CO2OPT Leistungen werden gemä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;der jeweiligen Leistungsbeschreibung gemäß Absatz C.1
                festgelegt. Alle Preise und Entgelte verstehen sich zzgl. der
                jeweils gültigen Umsatzsteuer.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Gebü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                r gilt am 1. Tag jedes Abrechnungszeitraums als fä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                lig. Der erste Abrechnungszeitraum beginnt mit dem Tag des
                Abonnements einer kostenpflichtigen Leistung, sofern nicht
                anders vereinbart ist. Der Kunde ist verpflichtet, die
                vereinbarte Vergü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ung spä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                estens innerhalb von 14 (vierzehn) Tagen nach Beginn jeder
                Abrechnungsperiode (Fä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ligkeit) zu zahlen. Wenn die Zahlung nach 14 Tagen teilweise
                ausgefü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                rt wird oder ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                erfäl
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ig ist, ist CO2OPT berechtigt, ab dem Zeitpunkt der Fä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ligkeit der Zahlung Zinsen in Hö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                e von 5% (Prozent) zusä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                zlich zum Basiszinssatz fü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>r</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;die Schulden des Kunden zu erheben.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Bei An- und Abreisen an einen anderen Ort als den CO2OPT Bü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                os erstattet der Kunde CO2OPT die Reisekosten, Unterkunft und
                Verpflegung bis zu einem zwischen den Parteien im Voraus
                vereinbarten Hö
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>c</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                hstbetrag. Nach einer Vereinbarung ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er den Hö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                c
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                hstbetrag kann CO2OPT das Transportmittel, die Unterkunft und
                die Mahlzeiten frei wä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                len.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die entsprechende Zahlung erfolgt ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er die von CO2OPT bereitgestellten Mittel und Zahlungswege. Bei
                Zahlung ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er einen Zahlungsdienstleister (inkl. SEPA-Zahlung, PayPal,
                Kreditkarte) ist der Kunde verpflichtet, zum Zeitpunkt der
                Zahlung ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er ausreichend Guthaben auf seinem Konto zu verfü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                en.&nbsp;
              </span>
            </li>
          </ol>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={5}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Wird die Zahlung nicht zum vereinbarten Zeitpunkt ausgefü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt, hat CO2OPT das Recht, entsprechende schriftliche Mahnungen
                auszusprechen. Die schriftliche Benachrichtigung kann u.a. per
                E-Mail oder ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er ein separater, von Dritten betriebener Zahlungsdienst/-konto
                erfolgen. Wenn der Kunde die Zahlung trotz schriftlicher Mahnung
                nicht erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lt, behä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t sich CO2OPT das Recht vor, die erbrachten Leistungen nach
                einer weiteren als letzte Mitteilung gekennzeichneten Mahnung
                innerhalb von 14 (vierzehn) Tagen bis zur Erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lung der Zahlung auszusetzen. CO2OPT behä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t sich das Recht vor, weitere rechtliche Schritte
                einzuleiten.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Falls eine Transaktion nicht von der Bank ausgefü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt oder zurü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                kgegeben wird, behä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t sich CO2OPT das Recht vor, eine zusä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                zliche Gebü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                r von EUR 12,00 zzgl. der entsprechenden Mehrwertsteuer auf den
                ursprü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                glich in der Rechnung gestellten Betrag zu erheben, es sei denn,
                der Kunde weist nach, dass CO2OPT kein oder nur ein geringerer
                Schaden entstanden ist.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT kann die jeweils geltenden Entgelte fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die einzelnen Leistungen ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dern und anpassen. Der Kunde wird ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er die Ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                derung rechtzeitig informiert und erhä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t die entsprechenden Ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                derungen in schriftlicher Form sechs (6) Wochen im Voraus. Im
                Falle des Widerspruchs hat der Kunde das Recht, den Vertrag zum
                Zeitpunkt des Inkrafttretens der Entgelt
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nderung zu kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digen. Die Kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digung muss CO2OPT mindestens vier (4) Wochen vor Inkrafttreten
                der Entgeltänderungen zugehen. Die Kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digung erfolgt schriftlich; die elektronische Form ist
                ausreichend.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Eine Rechnung wird zu Beginn jedes Abrechnungszeitraums
                ausgestellt. Die Rechnung muss die jeweiligen angebotenen
                Leistungen widerspiegeln, wie sie in der Leistungsbeschreibung
                und etwaigen Anpassungen oder Ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                derungen beschrieben sind. Kostenpflichtige Besprechungen
                zwischen CO2OPT und dem Kunden sowie Reisekosten des CO2OPT
                Personals zu einem vom Kunden festgelegten Standort, sofern es
                sich nicht um CO2OPT Bü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                os handelt, werden separat in Rechnung gestellt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;CO2OPT-Dienstleistungen zu zahlenden Kosten und Gebü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ren unterliegen der gesetzlichen Steuer (MwSt.). Die Rechnung
                enthä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t die jeweilige gesetzliche Umsatzsteuer zum Zeitpunkt der
                Leistungserbringung.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Einspruch gegen die von CO2OPT ausgestellten Rechnungen ist
                innerhalb von zwei (2) Wochen nach Eingang der Rechnung beim
                Kunden oder der zur Verfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung Stellung bei einem Zahlungsdienst/-konto schriftlich zu
                erheben. Andernfalls gilt die Rechnung als angenommen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              I. Änderungsvorbehalt&nbsp;
            </span>
          </p>
          <ol
            style={{
              listStyleType: 'decimal',
              marginLeft: '25.450000000000003px',
            }}
          >
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                CO2OPT nimmt angemessene Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen an seinen Diensten vor, soweit diese erforderlich sind,
                insbesondere zur Verbesserung der Funktionalitä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                &nbsp;oder zur Erfü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>l</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                lung gesetzlicher Anforderungen.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                CO2OPT wird notwendige und angemessene Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                derungen der AGB vornehmen, um neuen wirtschaftlichen bzw.
                rechtlichen Anforderungen zu entsprechen. Diese Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                derungen beruhen auf sachlichen Grü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                den. Der Kunde wird durch diese Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen nicht unangemessen benachteiligt.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                In den folgenden Fä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                len, aber nicht darauf beschrä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>n</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                kt, kö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                nte eine Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derung der AGB erfolgen:
              </span>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    zur Sicherstellung der Ü
                  </span>
                  <span style={{ fontSize: '10.0pt', color: 'black' }}>b</span>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    ereinstimmung mit dem geltenden Recht und der
                    Rechtsprechung, insbesondere nach Gesetzesänderungen;&nbsp;
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    zur Umsetzung gerichtlicher Entscheidungen und
                    Verwaltungsakte;&nbsp;
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    Verä
                  </span>
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    derungen der wirtschaftlichen Verhä
                  </span>
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>l</span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    tnisse, z. B. wenn neue Leistungen verfü
                  </span>
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    bar sind oder die Beschaffungskosten erheblich steigen,
                    oder&nbsp;
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    aus einem in den AGB genannten Grund.&nbsp;
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                Die zumutbaren Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen der AGB werden dem Kunden mindestens sechs (6) Wochen
                vor ihrem Inkrafttreten bekannt werden. Dies erfolgt
                elektronisch, z.B. per E-Mail und / oder beim Einloggen in das
                Kundenkonto. Der Kunde hat das Recht, diesen Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen zu widersprechen. Erfolgt innerhalb von zwei (2) Wochen
                kein Widerspruch, gilt das Schweigen als Zustimmung des Kunden
                zu den Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen, so dass diese zum jeweiligen Zeitpunkt in Kraft
                treten. Der Kunde wird auch im Falle einer konkludenten
                Zustimmung unterrichtet. Widerspricht der Kunde, so behä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t CO2OPT das Recht, den Vertrag zum nä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                c
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                hstmög
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ichen Kü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                digungstermin zu kü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                digen, wenn die unter diesem Abschnitt beschriebenen zumutbaren
                Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                derungen wesentliche Voraussetzungen fü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>r</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;eine ununterbrochene Leistungserbringung darstellen, wie
                sie unter Absatz G beschrieben ist.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              J. Haftung&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Parteien kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen keine Garantie ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ernehmen und haften daher nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die der anderen Partei durch die Nutzung der CO2OPT
                Leistungen gelieferten Ergebnisse, d.h. ob diese richtig,
                vollstä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dig und fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die konkrete oder kommerzielle Nutzung geeignet sind. Die
                Parteien haben keinen Gewährleistungsansprüche, insbesondere
                nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Beiträ
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e der Parteien und die erzielten Ergebnisse.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht, wenn der Kunde unvollstä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dige bzw. falsche Daten angibt und deshalb die dem Kunden zur
                Verfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung gestellten Ergebnisse falsch sind oder CO2OPT nicht
                vertragsgemä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;leisten kann.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;anfä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                glich vorhandene Mä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                gel an seiner Software und seinen Diensten. Der Kunde ist nicht
                berechtigt, Mä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                gel der Software und Dienste selbst zu beseitigen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Der Kunde ist verpflichtet, Stö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ungen oder Funktionsstö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ungen unverzü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich und prä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                z
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ise an CO2OPT zu melden. Im Schadensfall hat der Kunde den
                Schaden unverzü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich und so genau wie mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich zu melden und zu beschreiben. § 377 HGB gilt
                entsprechend.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;den Verlust von Daten bzw. Programmen, soweit der Schaden
                dadurch entstanden ist, dass der Kunde es unterlassen hat,
                Datensicherungen durchzufü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ren und dadurch sicherzustellen, dass verloren gegangene Daten
                mit vertretbarem Aufwand wiederhergestellt werden kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;Schadensersatz wegen vertraglicher und außervertraglicher
                Verstö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e nur bei Vorsatz oder grober Fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sigkeit.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Haftung ist ausgeschlossen bei Verletzung einer nicht
                wesentlichen Vertragspflicht, die auf leichter Fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sigkeit von gesetzlichen Vertretern, Mitarbeitern oder sonstigen
                Erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lungsgehilfen beruht.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Bei leicht fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                siger Verletzung wesentlicher Vertragspflichten ist die Haftung
                auf den Fall beschrä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                kt, dass die haftende Partei den mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lichen Schaden bei Vertragsschluss vorausgesehen hat oder unter
                Berü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ksichtigung der Umstä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                de, die ihr bekannt waren oder bekannt sein mussten, hä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                te voraussehen mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sen. Die Haftung ist auf den vertragstypischen, vorhersehbaren
                Schaden begrenzt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;grobe Fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sigkeit eines einfachen Erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lungsgehilfen (im Gegensatz zu leitenden Angestellten) außerhalb
                des Bereichs der wesentlichen Vertragspflichten ist die Haftung
                beschrä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                kt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Eine wesentliche Verpflichtung ist jede vertragliche
                Verpflichtung, die eine rechtmä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ige Vertragserfüllung gewä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rleistet und auf die der Kunde regelmä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ig vertraut oder vertrauen kann.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die vorstehenden Haftungsbeschränkungen gelten nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;Schadenersatzansprüc
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e aus der Verletzung von Leben, Kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                per und Gesundheit.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Haftung ist in jedem Fall von hö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                erer Gewalt (Force Majeure) fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Stö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung der CO2OPT Leistungen ausgeschlossen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Etwaige Schadenersatzansprüc
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e verjä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ren nach einem Jahr.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Es gelten die Bestimmungen des Produkthaftungsgesetzes
                (ProdHaftG).&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              K. Nichtverfu
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              ̈
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              gbarkeit der Dienstleistung&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;die Nichtverfu
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ̈
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                gbarkeit der Leistungen aufgrund hö
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>h</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                erer Gewalt, technischer Stö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                ungen von Dritten oder rechtswidriger Angriffe, die entweder
                CO2OPT selbst oder Drittanbieter betreffen, von denen die
                Software und Leistungen von CO2OPT abhä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                gig sind. Die Leistungen werden nach dem aktuellen Stand der
                Technik zur Verfü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ung gestellt, wobei notwendige Wartungsarbeiten zu Stö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ungen oder vorü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ergehender Nichtverfu
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ̈
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                gbarkeit fü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>h</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ren kö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                nen, so dass eine 100%ige Verfü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                barkeit (24 Stunden am Tag und 7 Tage die Woche an 365 Tagen im
                Jahr) nicht gewä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                rleistet werden kann.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                Im Schadensfall, wie z.B. bei technischen Fehlern, Auslastungen
                oder Verzö
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                erungen, hat CO2OPT zunä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>c</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                hst das Recht, die Stö
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>r</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ung zu beheben.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              L. Datenschutz&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT Datenschutzerklärung findet Anwendung auf die AGB.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Der Kunde ist verpflichtet, der Datenschutzerklärung von CO2OPT
                zuzustimmen, um die von CO2OPT angebotenen Dienste nutzen zu kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Wenn CO2OPT bei der Nutzung der Leistungen personenbezogene
                Daten fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;den Kunden verarbeitet oder Zugriff auf personenbezogene
                Daten des Kunden oder seiner Kunden hat, wird CO2OPT einen
                Datenschutzhinweis nach der DSGVO anbieten. Die Erklä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung kann per E-Mail unter&nbsp;
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: '#0260BF',
                }}
              >
                info@co2opt.com&nbsp;
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                angefordert werden.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT wird die vom Kunden zur Verfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung gestellten personenbezogenen Daten spä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                estens ein (1) Jahr nach Vertragsende automatisch lö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                chen oder anonymisieren, soweit nicht im Einzelfall abweichende
                Aufbewahrungsfristen bestehen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              M. Verschwiegenheit&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Beide Parteien werden es unterlassen, vertrauliche Informationen
                ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er die andere Partei weiterzugeben. Beide Parteien werden diese
                Informationen nur fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Zwecke der AGB und der konkreten Leistungen verwenden.
                Die Verpflichtung zur Vertraulichkeit gilt fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;drei (3) Jahre nach Beendigung des Vertrages. Absatz J
                bleibt hiervon unberü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Alle Informationen (ob schriftlich, elektronisch, mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dlich oder in anderer Form), die eine Partei der anderen Partei
                im Zusammenhang mit diesem Vertrag offenbart, sind vertraulich
                ("vertrauliche Informationen"). Vertrauliche Informationen
                umfassen unter anderem Geschä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                f
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                tsgeheimnisse, Zugänge, Know-how, Software, Quellcodes,
                Objektcodes, Algorithmen, Erfindungen und Geschäftspläne. Die
                Parteien vereinbaren hiermit, dass sie (i) vertrauliche
                Informationen streng vertraulich behandeln und die vertraulichen
                Informationen nur fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Zusammenarbeit verwenden, (ii) die vertraulichen
                Informationen nur an diejenigen Mitarbeiter und Dritte
                weitergeben, die diese Informationen fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Durchfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rung des Projekts kennen mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sen, vorausgesetzt, dass jede Partei sicherstellt, dass diese
                Mitarbeiter oder Dritte die Bestimmungen dieses Angebots und der
                Konditionen einhalten, als ob sie an dieses Angebot und
                Konditionen gebunden wä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                en, und (iii) die vertraulichen Informationen durch geeignete,
                dem Stand der Technik entsprechende Sicherheitsmaßnahmen vor
                unbefugtem Zugriff Dritter schü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                zen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Vertraulichkeitspflicht entfä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lt, wenn die Informationen (i) der anderen Partei zum Zeitpunkt
                der Vereinbarung der AGB bereits bekannt waren, und zwar nur
                dann, wenn der Nachweis erbracht werden kann, (ii) zu einem spä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                eren Zeitpunkt von einem Dritten offengelegt wurden, ohne dass
                eine Verletzung der Vertraulichkeit durch CO2OPT oder den Kunden
                vorliegt, (iii) zum Zeitpunkt der Vereinbarung bereits allgemein
                bekannt waren oder zu einem spä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                eren Zeitpunkt allgemein bekannt wurden, ohne dass ein Verstoß
                gegen die AGB vorliegt, (iv) von einer Partei mit vorheriger
                Zustimmung der anderen Partei fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;Zwecke verwendet werden, die nicht Gegenstand der AGB
                sind, oder (v) aufgrund einer gesetzlichen Verpflichtung oder
                einer gerichtlichen oder behö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dlichen Anordnung offengelegt werden mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sen; soweit zulä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sig und mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich ist, muss die zur Offenlegung verpflichtete Partei die
                andere Partei im Voraus ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er diese Verpflichtung informieren und die Mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lichkeit geben, Maßnahmen zu ergreifen, um die Offenlegung zu
                verhindern.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Jede Partei kann die andere Partei als Partner bezeichnen. Zu
                diesem Zweck kann jede Partei den Namen, die Marke und das Logo
                der anderen Partei verwenden. Beide Parteien kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen einen Link zur Website der jeweils anderen Partei
                einrichten; ein Widerruf ist jederzeit aus wichtigem Grund mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich; dieser Widerruf hat schriftlich zu erfolgen, wobei die
                elektronische Form ausreicht.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              N. Schlussbestimmungen&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Jede Ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragung von Rechten und Pflichten aus diesem Vertrag, in
                Teilen oder als Ganzes, bedarf der vorherigen schriftlichen
                Zustimmung von CO2OPT.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Sollten einzelne Bestimmungen ganz oder teilweise unwirksam sein
                oder werden, wird dadurch die Gü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                tigkeit der ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rigen Bestimmungen nicht berü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt. Die unwirksame Bestimmung ist durch eine wirksame zu
                ersetzen, die den wirtschaftlichen Interessen der Parteien am
                besten entspricht.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT ist berechtigt, die aus dem Vertrag Rechte und Pflichten
                ganz oder teilweise auf ein anderes Unternehmen zu ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragen. CO2OPT wird den Kunden ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er eine solche Ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragung informieren und fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;den Fall, dass der Kunde mit der Ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragung nicht einverstanden ist, hat der Kunde eine Frist von
                zwei (2) Wochen, um den Vertrag zu kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digen, oder den Vertrag zum regulä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                en Kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digungstermin kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Es gilt das Recht der Bundesrepublik Deutschland unter
                Ausschluss des UN-Kaufrechts (CISG). Ausschließlicher
                Gerichtsstand ist Hamburg, Deutschland.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
            }}
          >
            &nbsp;
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              CO2OPT GmbH <br />
              &nbsp;c/o St. Annenufer 2<br />
              &nbsp;20457 Hamburg&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              - im Folgenden „CO2OPT“ genannt&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              Diese Allgemeinen Geschä
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              f
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              tsbedingungen (im Folgenden als „AGB“ bezeichnet) stellen eine
              nicht bezahlte Rahmenvereinbarung zwischen CO2OPT und seinem
              Geschä
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              f
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              tspartner (im Folgenden als "Kunde" bezeichnet) fü
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              r
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              &nbsp;die Nutzung von CO2OPT-Kundenkonten, Abrechnungskonten und
              Leistungen dar (CO2OPT und der Kunde zusammen im Folgenden als
              "Parteien" bezeichnet).&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              Mit einem CO2OPT-Konto kann der Kunde bestimmte kostenpflichtige
              und kostenlose Leistungen nutzen (im Folgenden als
              "Software-Leistung" oder "Leistung" bezeichnet).&nbsp;
            </span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              A. Anwendungsbereich&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die AGB gelten fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;alle von CO2OPT angebotenen Dienstleistungen und sind
                untrennbarer Bestandteil des Vertrags zwischen CO2OPT und dem
                Kunden (im Folgenden als „Vertrag“ bezeichnet) sowie die
                vertragliche Grundlage fü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;alle vom Kunden genutzten Leistungen, es sei denn etwas
                anderes ist zwischen den Parteien vereinbart. Ebenso finden sie
                Anwendung auf Einzelverträ
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                e und Vereinbarungen zur Anpassung (Customization), zum
                Proof-of-Concept und fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;andere Leistungen zwischen den Parteien.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die AGB gelten ausschließlich. Allgemeine Geschäftsbedingungen
                des Kunden werden nicht Bestandteil des Vertrags zwischen CO2OPT
                und dem Kunden, es sei denn, CO2OPT stimmt diesen mit einer
                Erklärung in Textform ausdrücklich zu.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              B. Vertragsabschluss&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Nutzung der von CO2OPT angebotenen Leistungen beruht auf
                einem gesonderten Vertrag zwischen CO2OPT und dem Kunden. Die
                AGB werden Bestandteil dieses Vertrags, soweit sie keine
                widersprü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                hliche Klausel zum Vertrag enthalten.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Nur die zwischen den Parteien vereinbarten Dokumente, die ausdrü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                klich online oder offline Bestandteil dieses Vertrags werden,
                sind verbindlich. Mü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                dliche Vereinbarungen vor dem Vertragsschluss sind nicht
                rechtsverbindlich und etwaige mü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                dliche Vereinbarungen zwischen den Parteien werden durch den
                Vertrag ersetzt, es sei denn, es wird ausdrü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>c</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                klich vereinbart, dass sie weiterhin verbindlich bleiben. Der
                Vertrag ist in Textform zu erfassen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die Nutzung der CO2OPT Leistungen kontaktiert der Kunde
                CO2OPT ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er die Website. Der Kunde stellt alle relevanten Informationen
                und Dokumente zur Verfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ung, die für die Nutzung der Leistungen erforderlich sind.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Leistungen, die dem Kunden gegenü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>b</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er von Dritten erbracht werden, basieren auf einer gesonderten
                Vereinbarung, die ausschließlich zwischen dem Kunden und dem
                jeweiligen Drittanbieter abgeschlossen wird. CO2OPT ist keine
                Partei dieser Vereinbarung und trä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                t daher keine Pflichten gegenü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                er dem Kunden. Alle Leistungen von CO2OPT richten sich
                ausschließlich an Personen, die das 18. Lebensjahr vollendet
                haben und Unternehmer im Sinne des § 14 Abs. 1 BGB sind.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              C. Leistungsnutzung&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Kosten fu
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ̈
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                r die Leistungen von CO2OPT, die der Kunde abonniert, ergeben
                sich aus den einzelnen Vertragsdokumenten bzw. dem Angebot oder
                Leistungsschein (im Folgenden „Leistungsschein“) der jeweiligen
                Leistung.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Nutzung der Leistungen erfordert eine Registrierung als
                Kunde. Eine Registrierung als Privatperson ist nicht gestattet,
                da die Leistungen nur an gewerblichen Kunden richten, wie im
                Absatz B.6 beschrieben.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Kunde richtet durch das Registrierungsformular ein
                Firmenkonto (im Folgenden als „Konto“ bezeichnet) ein. Mit der
                Zustimmung zu den AGB und der Datenschutzerklärung von CO2OPT
                wird das Konto aktiviert.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Kunde ist verpflichtet, bei der Registrierung korrekte
                Angaben anzugeben und seine Kontaktdaten und sonstigen
                Pflichtangaben, einschließlich der für den Zahlungsverkehr
                erforderlichen Daten, auf dem neuesten Stand zu halten.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Eine natü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                liche Person, die sich im Namen eines Dritten registriert (z. B.
                eines Unternehmens, einer Vereinigung oder einer anderen natü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                lichen oder juristischen Person), kann bei Bedarf eine
                ordnungsgemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                e Vollmacht der juristischen Person vorlegen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er das Konto kann der Kunde, die von CO2OPT angebotenen
                Leistungen erwerben.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Kommunikation zwischen CO2OPT und dem Kunden erfolgt online,
                per Webanwendung, per E-Mail oder telefonisch. Der Kunde hat
                daher seine Kontaktdaten gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Absatz 4 dieses Abschnitts auf dem neuesten Stand zu
                halten und eingehende Nachrichten regelmä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>ß</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ig zu ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                erprüf
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                e
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Kunde hat keinen Anspruch auf Registrierung. CO2OPT behä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t sich das Recht vor, eine Registrierung ohne Begrü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                dung abzulehnen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              D. Leistungsumfang&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Der Leistungsumfang, die Funktionen und Merkmale der einzelnen
                Leistungen ergeben sich aus dem jeweiligen Leistungsschein der
                angebotenen Leistungen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Durch das Konto hat der Kunde Zugang zu den CO2OPT Leistungen.
                Die Software-Leistungen werden als webbasierter
                Software-as-a-Service Dienst zugestellt. Sie werden ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er einen Browser ausgefü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                rt. Eine lokale Installation ist daher nicht erforderlich.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Durch die Einrichtung eines Kontos und die Ü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ermittlung der Zugangsdaten rä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                u
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                mt CO2OPT dem Kunden ein nicht ausschließliches, nicht ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ertragbares, nicht unterlizenzierbares, zeitlich auf die
                Laufzeit des jeweiligen Vertrags beschrä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ktes Recht ein, die Softwareleistungen im Rahmen der
                Bestimmungen des Vertrags zu nutzen. Der Anwendungsbereich
                umfasst der Europä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                i
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                schen Wirtschaftsraums (EWR) und die Schweiz, es sei denn im
                Angebot ist etwas anderes geregelt.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Bei Erweiterung des Funktionsumfangs bestehender CO2OPT
                Leistungen oder beim Angebot neuer Leistungen wä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rend der Vertragslaufzeit finden die AGB automatisch auf die
                neuen Funktionen oder Leistungen Anwendung.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Qualitä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;der Leistungen hä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                gt von der Qualitä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;der empfangenen Rohdaten ab. Der Kunde ist allein
                verantwortlich fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die bereitgestellten Rohdaten. CO2OPT ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                erprüf
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;oder bewertet die empfangenen Rohdaten im Allgemeinen
                nicht.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT verbessert mit Hilfe von Algorithmen die angebotenen
                Dienste laufend weiter, z. B. durch Analyse der vom Kunden
                bereitgestellten Angaben, Daten und Dokumente (im Folgenden als
                „Kundendaten“ bezeichnet). Der Kunde räumt CO2OPT das Recht ein,
                die Kundendaten zu diesem Zweck zu verwenden.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die von CO2OPT angebotenen Softwareleistungen basieren auf
                Datenerhebungs-, Speicher- und Analysesystemen bzw. Software von
                Drittanbietern.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Um die Daten seiner eigenen Fahrzeugflotte zu verbinden,
                verwendet der Kunde unterschiedliche Übertragungskanäle,
                einschließlich, aber nicht beschrä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                kt auf APIs (Application Programming Interfaces), die von CO2OPT
                zusammen mit den verbundenen Leistungen bestimmter
                Fahrzeughersteller und anderer Drittanbieter zur Verfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                ung gestellt werden.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die ordnungsgemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                e Nutzung von CO2OPT Leistungen kö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                nen somit Software und Leistungen von Drittanbietern
                erforderlich sein (z. B. Analysetools, Datenbanksysteme usw.).
                CO2OPT hat keinen Einfluss auf eventuelle Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                derungen der Leistungen Dritter. Im Fall von Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                derungen durch Dritte kann CO2OPT bestimmte Leistungen mö
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                licherweise nicht im ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lichen Umfang bereitstellen. In solchen Fä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                len wird CO2OPT die erbrachte Leistung unter Berü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ksichtigung der Interessen des Kunden anpassen bzw. ggf. ä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                dern.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;die Leistungen Dritter kö
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                nten bestimmte Gebü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ren erhoben werden, die zwischen dem Drittanbieter und dem
                Kunden zu vereinbaren sind. CO2OPT ist bzw. wird keine Partei
                dieser Vereinbarung.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              E. Pflichten des Kunden&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde ist zur Mitwirkung verpflichtet, soweit es zur
                ordnungsgemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                en Durchfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                rung des Vertrags erforderlich ist. Insbesondere hat der Kunde
                CO2OPT unaufgefordert alle notwendigen Daten zu ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ermitteln, damit CO2OPT die entsprechenden Leistungen erbringen
                kann. Darü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>b</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er hinaus gewä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rleistet der Kunde CO2OPT Zugang zu den oben genannten, fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;die Erbringung von Leistungen notwendigen, Daten, gemä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;den geltenden Gesetzen und den internen Leitlinien des
                Kunden.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde berü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ksichtigt alle Unterlagen und Klarstellungen von CO2OPT und
                wendet sich im Falle eines Missverstä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                dnisses an CO2OPT. Darü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er hinaus prü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                f
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                t der Kunde sorgfä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                tig die Integritä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;und Gü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                tigkeit aller von CO2OPT erhaltenen Dokumente. Bei Fehlern ist
                er verpflichtet, CO2OPT unverzü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                lich zu benachrichtigen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde hat seine Konto- und Anmeldedaten geheim zu halten und
                vor unbefugten Dritten zu schü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                zen. Wenn ein Dritter Zugriff auf die oben genannten Daten oder
                das Konto erhält oder der Verdacht auf einen Datenverstoß
                besteht, muss der Kunde CO2OPT umgehend informieren und die
                Anmeldedaten unverzü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lich ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                dern, sobald er davon Kenntnis erlangt.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                CO2OPT Leistungen dü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                fen nur vom Kunden oder seinen Mitarbeitern genutzt werden. Die
                Nutzung durch oder der Zugang fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Dritte ist nicht gestattet.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde unternimmt alle erforderlichen Maßnahmen, um die
                Qualität der an CO2OPT übermittelten Daten sicherzustellen. Der
                Kunde stellt sicher, dass keine Malware (z. B. Viren) oder
                andere unerwü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                schte Daten oder Programme zusammen mit den erforderlichen Daten
                ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ertragen werden, die fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;den Zweck des Vertrages nicht relevant sind. CO2OPT haftet
                nicht fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Scha
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                d
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                en, die aus einem Verstoß gegen die oben genannte
                Sorgfaltspflicht resultieren.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde stellt sicher, dass die fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die Nutzung der CO2OPT Softwareleistungen erforderlichen
                Systemanforderungen erfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lt sind.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Im Falle eines Mangels hat der Kunde CO2OPT Funktionsprobleme,
                Fehlfunktionen oder Beeinträ
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                htigungen der Software unverzü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                lich und so genau wie mö
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                lich zu melden. Unterlä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                s
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                st der Kunde diese Verpflichtung, gilt § 536c BGB.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              F. Nutzungsrechte&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Nutzungsrechte werden gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;Absatz D.3 der AGB gewä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>h</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rt.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT gewä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                rt dem Kunden ein nicht exklusives, nicht ü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ertragbares, nicht unterlizenzierbares Recht, das zeitlich auf
                die jeweilige Vertragslaufzeit begrenzt ist, die
                Softwareleistungen, -dokumente und -informationen im Rahmen der
                Vertragsbedingungen und der AGB zu nutzen. Diese Übertragung von
                Rechten betrifft jedes Nutzungsrecht und jede bekannte und
                unbekannte Art der Nutzung. Bei Beendigung des Vertrages ist der
                Kunde verpflichtet, von der vorgenannten Nutzung umgehend
                abzusehen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Um die angebotenen Leistungen zu erbringen und
                weiterzuentwickeln, sammelt, speichert und verarbeitet CO2OPT
                Anwendungs- und Rohdaten des Kunden gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;den AGB. Die Zustimmung des Kunden erfolgt durch die
                Annahme der AGB.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT hat das ausschließliche Recht, die Ergebnisse und
                Entwicklungen zu verwenden, die sich aus den oben genannten
                Prozessen ergeben, einschließlich Datenerhebung, Verwendung,
                Analyse, Bewertung und Weiterverarbeitung.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Rohdaten, die der Kunde CO2OPT gemä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;den AGB zur Verfü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ung stellt, bleiben das alleinige Eigentum des Kunden. Mit
                Beendigung des Vertrages hat der Kunde das Recht, die Lö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                chung der Daten zu verlangen. CO2OPT lö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                cht daraufhin die Daten spä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                estens ein (1) Jahr nach Ablauf der Vertragslaufzeit.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Alle schutzfähigen und nicht schutzfähigen Arbeitsergebnisse,
                insbesondere geistiges Eigentum (im Folgenden "IP" genannt),
                Rechte (einschließlich Urheberrechte, gewerbliche Schutzrechte,
                wie Patente und alle IP-bezogenen Nutzungsrechte) an und im
                Zusammenhang mit Werken oder Derivaten davon, Erfindungen und
                Informationen, einschließlich, aber nicht beschränkt auf,
                Algorithmen, Software, Quellcodes, Objektcodes, Materialien,
                Methoden, Werkzeuge, Know-how, Analysen und Produkte,
                Produkt-Updates und Produkt-Upgrades, sowie alle Rechte an und
                fü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;alle Entwicklungen und alle anderen Arbeitsprodukte, die
                aus der Verwendung der Daten des Auftraggebers durch CO2OPT im
                Rahmen des Projekts von CO2OPT generiert, erstellt und/oder
                abgeleitet werden, sind alleiniges Eigentum von CO2OPT. Nur
                CO2OPT hat das Recht, die Arbeitsergebnisse kommerziell zu
                verwerten und anderweitig zu nutzen, einschließlich, aber nicht
                beschränkt auf die Weiterentwicklung aller im Rahmen des
                Vertrags entwickelten Arbeitsergebnisse. Diese Rechte umfassen
                auch das Recht die Arbeitsergebnisse unterzulizenzieren, zu
                verarbeiten und weiterzuentwickeln. Ungeachtet des
                ausschließlichen Rechts von CO2OPT, die Arbeitsergebnisse
                kommerziell zu verwerten und anderweitig zu nutzen, hat der
                Kunde das Recht, diese für interne Zwecke zu nutzen.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT ist Hersteller der entsprechenden Datenbanken und
                Softwareleistungen nach § 87a Urheberrechtsgesetz. Rechte an
                geistigem Eigentum, Urheberrechten bzw. Nutzungsrechten in
                anonymer Form verbleiben nach Beendigung dieses Vertrages
                ausschließlich bei CO2OPT. CO2OPT hat das Recht, diese Daten zu
                speichern, zu verwenden, zu analysieren und zu verarbeiten. Ihre
                Derivate bleiben auch nach Vertragsende das alleinige Eigentum
                von CO2OPT.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                CO2OPT hat das Recht, nach eigenem Ermessen die Registrierung
                von Eigentumsrechten wie einem nationalen oder internationalen
                Patent oder einem Gebrauchsmuster fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;eine im Rahmen des Projekts abgeleitete Erfindung zu
                beantragen und die entsprechenden Eigentumsrechte auszuü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                en. Der Kunde hat jedoch kein Recht auf Registrierung von
                Eigentumsrechten oder Registrierung und Verwertung der
                relevanten Arbeitsergebnisse.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Wenn ein Dritter geltend macht, dass die vom Kunden gelieferten
                Daten seine Eigentumsrechte verletzen, einschließlich, aber
                nicht beschränkt auf Urheberrecht, Unterschlagung von Geschä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                f
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                tsgeheimnissen oder anderen Rechten an geistigem Eigentum, hat
                der Kunde CO2OPT unverzü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                lich zu informieren. Auf Verlangen und soweit begrü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                det oder gesetzlich vorgeschrieben, werden die Parteien bei der
                Abwehr oder Beilegung solcher Ansprü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                he angemessen zusammenarbeiten.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                Der Kunde darf die CO2OPT-Software nicht ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                dern. Insbesondere ist der Kunde nicht berechtigt, Funktionen
                mittels "Reverse Engineering" zu untersuchen, zu dekompilieren
                (mit Ausnahme von § 69e UrhG), in die Bestandteilten zu zerlegen
                oder als Grundlage fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;die Erstellung eigener Software und Leistungen zu
                verwenden.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              G. Kü
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              n
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              digung&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Nach Annahme der AGB werden CO2OPT Leistungen gemä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;den Bestimmungen dieses Absatzes verfü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                bar.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die AGB werden erst dann separat beendet, wenn jede einzelne
                CO2OPT Leistung beendet wurde. Die Kü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                digungsfrist der AGB beträ
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t einen Monat nach dem Ende des Monats, in dem CO2OPT die
                Kündigungserkla
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ̈
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                rung fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;die AGB erhalten hat.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                &nbsp;Falls der Kunde sein Konto oder die Nutzung der Leistungen
                kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                digt, hat der Kunde keinen Anspruch auf Rü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                kerstattung von Gebü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ren, die der Kunde vor dem Zeitpunkt der Kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                digung fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;die Nutzung der Leistungen gezahlt hat.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Das Recht auf außerordentliche Kündigung bleibt für die
                gesetzlich geregelte Fälle unberührt. Bei Widerspruch des Kunden
                hinsichtlich einer notwendigen technischen Änderung der Dienste
                behält sich CO2OPT das Recht zur außerordentlichen Kündigung
                seiner Dienste vor.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Das Recht auf Kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                digung aus wichtigem Grund bleibt fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;beide Parteien hiervon unberü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                rt. <br />
                &nbsp;Ein wichtiger Grund liegt beispielsweise vor wenn:
              </span>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      color: 'black',
                      fontSize: '15px',
                    }}
                  >
                    Ein wesentlicher Verstoß gegen die Verpflichtungen einer der
                    Parteien, der nicht gemäß dem Einzelvertrag behoben wurde
                    und der es der anderen Partei unmö
                  </span>
                  <span
                    style={{
                      fontFamily: '"Arial",sans-serif',
                      color: 'black',
                      fontSize: '10.0pt',
                    }}
                  >
                    g
                  </span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      color: 'black',
                      fontSize: '15px',
                    }}
                  >
                    lich macht, ihren Verpflichtungen nachzukommen.
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      color: 'black',
                      fontSize: '10.0pt',
                    }}
                  >
                    &nbsp;Insolvenzverfahren gegen eine der Parteien oder
                    Ablehnung dieses Verfahrens wegen fehlenden Vermö
                  </span>
                  <span
                    style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
                  >
                    g
                  </span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    ens.&nbsp;
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                In jedem anderen Fall werden die AGB nach den gesetzlichen
                Bestimmungen beendet.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Kü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                digung hat schriftlich zu erfolgen; die elektronische Form ist
                ausreichend.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Im Falle eines wiederholten und schwerwiegenden Verstoßes gegen
                die AGB ist CO2OPT berechtigt, den Zugang des Kunden zum Account
                zu sperren und den Vertrag außerordentlich zu kündigen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              H. Zahlungsbedingungen&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Kosten und Gebü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                ren fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                &nbsp;einzelne CO2OPT Leistungen werden gemä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                ß
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;der jeweiligen Leistungsbeschreibung gemäß Absatz C.1
                festgelegt. Alle Preise und Entgelte verstehen sich zzgl. der
                jeweils gültigen Umsatzsteuer.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die Gebü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                r gilt am 1. Tag jedes Abrechnungszeitraums als fä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                lig. Der erste Abrechnungszeitraum beginnt mit dem Tag des
                Abonnements einer kostenpflichtigen Leistung, sofern nicht
                anders vereinbart ist. Der Kunde ist verpflichtet, die
                vereinbarte Vergü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ung spä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                estens innerhalb von 14 (vierzehn) Tagen nach Beginn jeder
                Abrechnungsperiode (Fä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ligkeit) zu zahlen. Wenn die Zahlung nach 14 Tagen teilweise
                ausgefü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                rt wird oder ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                erfäl
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                ig ist, ist CO2OPT berechtigt, ab dem Zeitpunkt der Fä
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ligkeit der Zahlung Zinsen in Hö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                e von 5% (Prozent) zusä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                zlich zum Basiszinssatz fü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>r</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                &nbsp;die Schulden des Kunden zu erheben.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Bei An- und Abreisen an einen anderen Ort als den CO2OPT Bü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                os erstattet der Kunde CO2OPT die Reisekosten, Unterkunft und
                Verpflegung bis zu einem zwischen den Parteien im Voraus
                vereinbarten Hö
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>c</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                hstbetrag. Nach einer Vereinbarung ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er den Hö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                c
              </span>
              <span
                style={{ fontFamily: '"Open Sans",sans-serif', color: 'black' }}
              >
                hstbetrag kann CO2OPT das Transportmittel, die Unterkunft und
                die Mahlzeiten frei wä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                len.&nbsp;
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                Die entsprechende Zahlung erfolgt ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er die von CO2OPT bereitgestellten Mittel und Zahlungswege. Bei
                Zahlung ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                er einen Zahlungsdienstleister (inkl. SEPA-Zahlung, PayPal,
                Kreditkarte) ist der Kunde verpflichtet, zum Zeitpunkt der
                Zahlung ü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                  fontSize: '10.0pt',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                  fontSize: '15px',
                }}
              >
                er ausreichend Guthaben auf seinem Konto zu verfü
              </span>
              <span
                style={{ fontFamily: '"Arial",sans-serif', color: 'black' }}
              >
                g
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                en.&nbsp;
              </span>
            </li>
          </ol>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={5}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Wird die Zahlung nicht zum vereinbarten Zeitpunkt ausgefü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt, hat CO2OPT das Recht, entsprechende schriftliche Mahnungen
                auszusprechen. Die schriftliche Benachrichtigung kann u.a. per
                E-Mail oder ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er ein separater, von Dritten betriebener Zahlungsdienst/-konto
                erfolgen. Wenn der Kunde die Zahlung trotz schriftlicher Mahnung
                nicht erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lt, behä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t sich CO2OPT das Recht vor, die erbrachten Leistungen nach
                einer weiteren als letzte Mitteilung gekennzeichneten Mahnung
                innerhalb von 14 (vierzehn) Tagen bis zur Erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lung der Zahlung auszusetzen. CO2OPT behä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t sich das Recht vor, weitere rechtliche Schritte
                einzuleiten.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Falls eine Transaktion nicht von der Bank ausgefü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt oder zurü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                kgegeben wird, behä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t sich CO2OPT das Recht vor, eine zusä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                zliche Gebü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                r von EUR 12,00 zzgl. der entsprechenden Mehrwertsteuer auf den
                ursprü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                glich in der Rechnung gestellten Betrag zu erheben, es sei denn,
                der Kunde weist nach, dass CO2OPT kein oder nur ein geringerer
                Schaden entstanden ist.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT kann die jeweils geltenden Entgelte fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die einzelnen Leistungen ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dern und anpassen. Der Kunde wird ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er die Ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                derung rechtzeitig informiert und erhä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t die entsprechenden Ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                derungen in schriftlicher Form sechs (6) Wochen im Voraus. Im
                Falle des Widerspruchs hat der Kunde das Recht, den Vertrag zum
                Zeitpunkt des Inkrafttretens der Entgelt
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nderung zu kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digen. Die Kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digung muss CO2OPT mindestens vier (4) Wochen vor Inkrafttreten
                der Entgeltänderungen zugehen. Die Kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digung erfolgt schriftlich; die elektronische Form ist
                ausreichend.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Eine Rechnung wird zu Beginn jedes Abrechnungszeitraums
                ausgestellt. Die Rechnung muss die jeweiligen angebotenen
                Leistungen widerspiegeln, wie sie in der Leistungsbeschreibung
                und etwaigen Anpassungen oder Ä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                derungen beschrieben sind. Kostenpflichtige Besprechungen
                zwischen CO2OPT und dem Kunden sowie Reisekosten des CO2OPT
                Personals zu einem vom Kunden festgelegten Standort, sofern es
                sich nicht um CO2OPT Bü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                os handelt, werden separat in Rechnung gestellt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;CO2OPT-Dienstleistungen zu zahlenden Kosten und Gebü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ren unterliegen der gesetzlichen Steuer (MwSt.). Die Rechnung
                enthä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                t die jeweilige gesetzliche Umsatzsteuer zum Zeitpunkt der
                Leistungserbringung.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Einspruch gegen die von CO2OPT ausgestellten Rechnungen ist
                innerhalb von zwei (2) Wochen nach Eingang der Rechnung beim
                Kunden oder der zur Verfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung Stellung bei einem Zahlungsdienst/-konto schriftlich zu
                erheben. Andernfalls gilt die Rechnung als angenommen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              I. Änderungsvorbehalt&nbsp;
            </span>
          </p>
          <ol
            style={{
              listStyleType: 'decimal',
              marginLeft: '25.450000000000003px',
            }}
          >
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                CO2OPT nimmt angemessene Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen an seinen Diensten vor, soweit diese erforderlich sind,
                insbesondere zur Verbesserung der Funktionalitä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                &nbsp;oder zur Erfü
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>l</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                lung gesetzlicher Anforderungen.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                CO2OPT wird notwendige und angemessene Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                derungen der AGB vornehmen, um neuen wirtschaftlichen bzw.
                rechtlichen Anforderungen zu entsprechen. Diese Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                derungen beruhen auf sachlichen Grü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                den. Der Kunde wird durch diese Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen nicht unangemessen benachteiligt.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                In den folgenden Fä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                len, aber nicht darauf beschrä
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>n</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                kt, kö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                nte eine Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derung der AGB erfolgen:
              </span>
              <ol style={{ listStyleType: 'lower-alpha' }}>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    zur Sicherstellung der Ü
                  </span>
                  <span style={{ fontSize: '10.0pt', color: 'black' }}>b</span>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    ereinstimmung mit dem geltenden Recht und der
                    Rechtsprechung, insbesondere nach Gesetzesänderungen;&nbsp;
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    zur Umsetzung gerichtlicher Entscheidungen und
                    Verwaltungsakte;&nbsp;
                  </span>
                </li>
                <li>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    Verä
                  </span>
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    derungen der wirtschaftlichen Verhä
                  </span>
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>l</span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    tnisse, z. B. wenn neue Leistungen verfü
                  </span>
                  <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
                  <span
                    style={{
                      fontFamily: '"Open Sans",sans-serif',
                      fontSize: '10.0pt',
                      color: 'black',
                    }}
                  >
                    bar sind oder die Beschaffungskosten erheblich steigen,
                    oder&nbsp;
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                    aus einem in den AGB genannten Grund.&nbsp;
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                Die zumutbaren Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen der AGB werden dem Kunden mindestens sechs (6) Wochen
                vor ihrem Inkrafttreten bekannt werden. Dies erfolgt
                elektronisch, z.B. per E-Mail und / oder beim Einloggen in das
                Kundenkonto. Der Kunde hat das Recht, diesen Ä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen zu widersprechen. Erfolgt innerhalb von zwei (2) Wochen
                kein Widerspruch, gilt das Schweigen als Zustimmung des Kunden
                zu den Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                derungen, so dass diese zum jeweiligen Zeitpunkt in Kraft
                treten. Der Kunde wird auch im Falle einer konkludenten
                Zustimmung unterrichtet. Widerspricht der Kunde, so behä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                t CO2OPT das Recht, den Vertrag zum nä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                c
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                hstmög
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ichen Kü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                digungstermin zu kü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>n</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                digen, wenn die unter diesem Abschnitt beschriebenen zumutbaren
                Ä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                derungen wesentliche Voraussetzungen fü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>r</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;eine ununterbrochene Leistungserbringung darstellen, wie
                sie unter Absatz G beschrieben ist.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              J. Haftung&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Parteien kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen keine Garantie ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ernehmen und haften daher nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die der anderen Partei durch die Nutzung der CO2OPT
                Leistungen gelieferten Ergebnisse, d.h. ob diese richtig,
                vollstä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dig und fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die konkrete oder kommerzielle Nutzung geeignet sind. Die
                Parteien haben keinen Gewährleistungsansprüche, insbesondere
                nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Beiträ
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e der Parteien und die erzielten Ergebnisse.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht, wenn der Kunde unvollstä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dige bzw. falsche Daten angibt und deshalb die dem Kunden zur
                Verfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung gestellten Ergebnisse falsch sind oder CO2OPT nicht
                vertragsgemä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;leisten kann.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;anfä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                glich vorhandene Mä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                gel an seiner Software und seinen Diensten. Der Kunde ist nicht
                berechtigt, Mä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                gel der Software und Dienste selbst zu beseitigen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Der Kunde ist verpflichtet, Stö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ungen oder Funktionsstö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ungen unverzü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich und prä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                z
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ise an CO2OPT zu melden. Im Schadensfall hat der Kunde den
                Schaden unverzü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich und so genau wie mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich zu melden und zu beschreiben. § 377 HGB gilt
                entsprechend.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;den Verlust von Daten bzw. Programmen, soweit der Schaden
                dadurch entstanden ist, dass der Kunde es unterlassen hat,
                Datensicherungen durchzufü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ren und dadurch sicherzustellen, dass verloren gegangene Daten
                mit vertretbarem Aufwand wiederhergestellt werden kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT haftet fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;Schadensersatz wegen vertraglicher und außervertraglicher
                Verstö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e nur bei Vorsatz oder grober Fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sigkeit.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Haftung ist ausgeschlossen bei Verletzung einer nicht
                wesentlichen Vertragspflicht, die auf leichter Fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sigkeit von gesetzlichen Vertretern, Mitarbeitern oder sonstigen
                Erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lungsgehilfen beruht.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Bei leicht fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                siger Verletzung wesentlicher Vertragspflichten ist die Haftung
                auf den Fall beschrä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                kt, dass die haftende Partei den mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lichen Schaden bei Vertragsschluss vorausgesehen hat oder unter
                Berü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                c
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ksichtigung der Umstä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                de, die ihr bekannt waren oder bekannt sein mussten, hä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                te voraussehen mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sen. Die Haftung ist auf den vertragstypischen, vorhersehbaren
                Schaden begrenzt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;grobe Fahrlä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sigkeit eines einfachen Erfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lungsgehilfen (im Gegensatz zu leitenden Angestellten) außerhalb
                des Bereichs der wesentlichen Vertragspflichten ist die Haftung
                beschrä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                kt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Eine wesentliche Verpflichtung ist jede vertragliche
                Verpflichtung, die eine rechtmä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ige Vertragserfüllung gewä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rleistet und auf die der Kunde regelmä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                ß
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ig vertraut oder vertrauen kann.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die vorstehenden Haftungsbeschränkungen gelten nicht fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;Schadenersatzansprüc
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e aus der Verletzung von Leben, Kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                per und Gesundheit.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Haftung ist in jedem Fall von hö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                erer Gewalt (Force Majeure) fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Stö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung der CO2OPT Leistungen ausgeschlossen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Etwaige Schadenersatzansprüc
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                e verjä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ren nach einem Jahr.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Es gelten die Bestimmungen des Produkthaftungsgesetzes
                (ProdHaftG).&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              K. Nichtverfu
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Arial",sans-serif',
                color: 'black',
              }}
            >
              ̈
            </span>
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              gbarkeit der Dienstleistung&nbsp;
            </span>
          </p>
          <ol style={{ listStyleType: 'decimal', marginLeft: '26px' }}>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                CO2OPT haftet nicht fü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                &nbsp;die Nichtverfu
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ̈
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                gbarkeit der Leistungen aufgrund hö
              </span>
              <span style={{ fontSize: '10.0pt', color: 'black' }}>h</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                erer Gewalt, technischer Stö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                ungen von Dritten oder rechtswidriger Angriffe, die entweder
                CO2OPT selbst oder Drittanbieter betreffen, von denen die
                Software und Leistungen von CO2OPT abhä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                gig sind. Die Leistungen werden nach dem aktuellen Stand der
                Technik zur Verfü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ung gestellt, wobei notwendige Wartungsarbeiten zu Stö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ungen oder vorü
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ergehender Nichtverfu
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ̈
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                gbarkeit fü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>h</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ren kö
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                n
              </span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                nen, so dass eine 100%ige Verfü
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
              <span style={{ fontFamily: '"Open Sans",sans-serif' }}>
                barkeit (24 Stunden am Tag und 7 Tage die Woche an 365 Tagen im
                Jahr) nicht gewä
              </span>
              <span
                style={{
                  fontFamily: '"Arial",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                rleistet werden kann.
              </span>
            </li>
            <li>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                Im Schadensfall, wie z.B. bei technischen Fehlern, Auslastungen
                oder Verzö
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>g</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                erungen, hat CO2OPT zunä
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>c</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                hst das Recht, die Stö
              </span>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>r</span>
              <span
                style={{
                  fontFamily: '"Open Sans",sans-serif',
                  fontSize: '10.0pt',
                  color: 'black',
                }}
              >
                ung zu beheben.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              L. Datenschutz&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT Datenschutzerklärung findet Anwendung auf die AGB.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Der Kunde ist verpflichtet, der Datenschutzerklärung von CO2OPT
                zuzustimmen, um die von CO2OPT angebotenen Dienste nutzen zu kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Wenn CO2OPT bei der Nutzung der Leistungen personenbezogene
                Daten fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;den Kunden verarbeitet oder Zugriff auf personenbezogene
                Daten des Kunden oder seiner Kunden hat, wird CO2OPT einen
                Datenschutzhinweis nach der DSGVO anbieten. Die Erklä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung kann per E-Mail unter&nbsp;
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: '#0260BF',
                }}
              >
                info@co2opt.com&nbsp;
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                angefordert werden.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT wird die vom Kunden zur Verfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ung gestellten personenbezogenen Daten spä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                estens ein (1) Jahr nach Vertragsende automatisch lö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                chen oder anonymisieren, soweit nicht im Einzelfall abweichende
                Aufbewahrungsfristen bestehen.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              M. Verschwiegenheit&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Beide Parteien werden es unterlassen, vertrauliche Informationen
                ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er die andere Partei weiterzugeben. Beide Parteien werden diese
                Informationen nur fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Zwecke der AGB und der konkreten Leistungen verwenden.
                Die Verpflichtung zur Vertraulichkeit gilt fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;drei (3) Jahre nach Beendigung des Vertrages. Absatz J
                bleibt hiervon unberü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Alle Informationen (ob schriftlich, elektronisch, mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dlich oder in anderer Form), die eine Partei der anderen Partei
                im Zusammenhang mit diesem Vertrag offenbart, sind vertraulich
                ("vertrauliche Informationen"). Vertrauliche Informationen
                umfassen unter anderem Geschä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                f
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                tsgeheimnisse, Zugänge, Know-how, Software, Quellcodes,
                Objektcodes, Algorithmen, Erfindungen und Geschäftspläne. Die
                Parteien vereinbaren hiermit, dass sie (i) vertrauliche
                Informationen streng vertraulich behandeln und die vertraulichen
                Informationen nur fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Zusammenarbeit verwenden, (ii) die vertraulichen
                Informationen nur an diejenigen Mitarbeiter und Dritte
                weitergeben, die diese Informationen fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;die Durchfü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rung des Projekts kennen mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sen, vorausgesetzt, dass jede Partei sicherstellt, dass diese
                Mitarbeiter oder Dritte die Bestimmungen dieses Angebots und der
                Konditionen einhalten, als ob sie an dieses Angebot und
                Konditionen gebunden wä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                en, und (iii) die vertraulichen Informationen durch geeignete,
                dem Stand der Technik entsprechende Sicherheitsmaßnahmen vor
                unbefugtem Zugriff Dritter schü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                zen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Die Vertraulichkeitspflicht entfä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lt, wenn die Informationen (i) der anderen Partei zum Zeitpunkt
                der Vereinbarung der AGB bereits bekannt waren, und zwar nur
                dann, wenn der Nachweis erbracht werden kann, (ii) zu einem spä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                eren Zeitpunkt von einem Dritten offengelegt wurden, ohne dass
                eine Verletzung der Vertraulichkeit durch CO2OPT oder den Kunden
                vorliegt, (iii) zum Zeitpunkt der Vereinbarung bereits allgemein
                bekannt waren oder zu einem spä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                t
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                eren Zeitpunkt allgemein bekannt wurden, ohne dass ein Verstoß
                gegen die AGB vorliegt, (iv) von einer Partei mit vorheriger
                Zustimmung der anderen Partei fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;Zwecke verwendet werden, die nicht Gegenstand der AGB
                sind, oder (v) aufgrund einer gesetzlichen Verpflichtung oder
                einer gerichtlichen oder behö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                dlichen Anordnung offengelegt werden mü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sen; soweit zulä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                s
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                sig und mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich ist, muss die zur Offenlegung verpflichtete Partei die
                andere Partei im Voraus ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er diese Verpflichtung informieren und die Mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lichkeit geben, Maßnahmen zu ergreifen, um die Offenlegung zu
                verhindern.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Jede Partei kann die andere Partei als Partner bezeichnen. Zu
                diesem Zweck kann jede Partei den Namen, die Marke und das Logo
                der anderen Partei verwenden. Beide Parteien kö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                nen einen Link zur Website der jeweils anderen Partei
                einrichten; ein Widerruf ist jederzeit aus wichtigem Grund mö
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                g
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                lich; dieser Widerruf hat schriftlich zu erfolgen, wobei die
                elektronische Form ausreicht.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
              background: 'none',
            }}
          >
            <span
              style={{
                fontSize: '15px',
                fontFamily: '"Open Sans",sans-serif',
                color: 'black',
              }}
            >
              N. Schlussbestimmungen&nbsp;
            </span>
          </p>
          <ol
            style={{ marginBottom: '0cm', marginTop: '0cm' }}
            type={1}
            start={1}
          >
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Jede Ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragung von Rechten und Pflichten aus diesem Vertrag, in
                Teilen oder als Ganzes, bedarf der vorherigen schriftlichen
                Zustimmung von CO2OPT.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Sollten einzelne Bestimmungen ganz oder teilweise unwirksam sein
                oder werden, wird dadurch die Gü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                l
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                tigkeit der ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rigen Bestimmungen nicht berü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                h
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                rt. Die unwirksame Bestimmung ist durch eine wirksame zu
                ersetzen, die den wirtschaftlichen Interessen der Parteien am
                besten entspricht.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                CO2OPT ist berechtigt, die aus dem Vertrag Rechte und Pflichten
                ganz oder teilweise auf ein anderes Unternehmen zu ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragen. CO2OPT wird den Kunden ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                er eine solche Ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragung informieren und fü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                &nbsp;den Fall, dass der Kunde mit der Ü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                b
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                ertragung nicht einverstanden ist, hat der Kunde eine Frist von
                zwei (2) Wochen, um den Vertrag zu kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digen, oder den Vertrag zum regulä
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                r
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                en Kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digungstermin kü
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Arial",sans-serif',
                  color: 'black',
                }}
              >
                n
              </span>
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                digen.&nbsp;
              </span>
            </li>
            <li
              style={{
                margin: '0cm',
                fontSize: '18px',
                fontFamily: '"Calibri",sans-serif',
                marginTop: '5.0pt',
                marginBottom: '5.0pt',
                background: 'none',
              }}
            >
              <span
                style={{
                  fontSize: '15px',
                  fontFamily: '"Open Sans",sans-serif',
                  color: 'black',
                }}
              >
                Es gilt das Recht der Bundesrepublik Deutschland unter
                Ausschluss des UN-Kaufrechts (CISG). Ausschließlicher
                Gerichtsstand ist Hamburg, Deutschland.&nbsp;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
            }}
          >
            <span style={{ fontFamily: '"Open Sans",sans-serif' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '5.0pt',
              marginRight: '0cm',
              marginBottom: '5.0pt',
              marginLeft: '0cm',
              fontSize: '18px',
              fontFamily: '"Calibri",sans-serif',
              margin: '0cm',
            }}
          >
            &nbsp;
          </p>
        </div>
      </Container>
      </Box>
    
  );
};

export default TermsConditions;
