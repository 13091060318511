import React from "react";
import { Box, Button, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import { orgValidationSchema } from "../../../utils/string_utils";
import { fetchOrgAutofillData } from "../../../services/fleet_service";
import lang_config from "../../../language";
const OrgForm = ({ questions, orgData, setOrgData, next, setNext, lang }) => {
  const formik_org = useFormik({
    initialValues: orgData,
    validationSchema: orgValidationSchema,
    onSubmit: (values) => {
      setOrgData(values);
      setNext(next + 1);
    },
  });

  const getOrgAutofillData = async (orgName) => {
    const res = await fetchOrgAutofillData(orgName);
    if (res.status === 200) {
      const location = res.data.location;
      formik_org.setValues((state) => ({
        ...state,
        zip_code: location.postal_code,
        city: location.region,
        country: location.country,
      }));
    }
  };

  const handleBlur = (e) => {
    if (e.target.id === "21") {
      getOrgAutofillData(e.target.value);
    }
  };

  return (
    <Box
      style={{
        maxWidth: 700,
        width: "100%",
      }}
      mx="1rem"
    >
      <form style={{ width: "100%" }} onSubmit={formik_org.handleSubmit}>
        {Object.entries(questions["organization_data"]["questions"]).map(
          (item, i) => (
            <TextField
              margin={"normal"}
              name={item[0]}
              variant="outlined"
              required
              label={item[1]["question_phrase"][lang]}
              type={item[1]["question_type"] === "integer" ? "number" : "text"}
              id={item[1]["id"]}
              value={formik_org.values[item[0]]}
              onChange={formik_org.handleChange}
              error={
                formik_org.touched[item[0]] &&
                Boolean(formik_org.errors[item[0]])
              }
              helperText={
                formik_org.touched[item[0]] && formik_org.errors[item[0]]
              }
              fullWidth
              onBlur={handleBlur}
            />
          )
        )}
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "0.5rem auto",
          }}
        >
          <Button
            variant="contained"
            type={"submit"}
            disableElevation
            color="primary"
            style={{ textTransform: "unset" }}
            data-hook="next-button"
          >
            {lang_config[lang].buttons.next}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default OrgForm;
