import axios from "axios";

export const base_url = process.env.REACT_APP_BASE_URL;
export const fleet_config = process.env.REACT_APP_FLEET_CONFIG;
export const people_datalabs_url = process.env.REACT_APP_PEOPLEDATALABS_API_URL;

export const SavingsEstimate = async (totalKm, fuelCost, avgComp) => {
  return await axios
    .get(
      `${fleet_config}/initial-savings-estimate/${totalKm}/${fuelCost}/${avgComp}`
    )
    .then((res) => res)
    .catch(function (error) {
      return error.response;
    });
};
export const SignUpUser = async (data) => {
  const form = new FormData();

  form.append("email", data.email);
  form.append("password", data.password);
  form.append("first_name", data.first_name);
  form.append("last_name", data.last_name);

  form.append("locale", data.locale);

  return await axios
    .post(`${base_url}/sign-up`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((res) => res)
    .catch(function (error) {
      return error.response;
    });
};

export const IntegrateFleetData = async (data, access_token) => {
  return await axios
    .post(`${fleet_config}/organization`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => res)
    .catch(function (error) {
      return error.response;
    });
};
export const silentLogin = async (data) => {
  return await axios
    .get(`https://api.co2opt.com/sso/sso/get-site-access/${data.code}`)
    .then((res) => res)
    .catch(function (error) {
      return error.response;
    });
};

export const checkUserExist = async (email) => {
  return await axios
    .get(`${base_url}/site-check/${email}`)
    .then((res) => res)
    .catch(function (error) {
      return error.response;
    });
};
export const validatePayload = async (payload) => {
  return await axios
    .post(`${fleet_config}/validate-organization-data`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      return error.response;
    });
};
export const fetchOrgAutofillData = async (orgName) => {
  return await axios
    .get(`${people_datalabs_url}/${orgName}`)
    .then((res) => res)
    .catch(function (error) {
      return error.response;
    });
};
export const Login = async (data) => {
  const form = new FormData();

  form.append("email", data.email);
  form.append("password", data.password);

  return await axios
    .post(`${base_url}/sign-in`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res)
    .catch(function (error) {
      console.error("E10034", error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const tireDatabase = () => {
  return axios
    .get(`${fleet_config}/knowledge/tires`)
    .then((res) => res)
    .catch(function (error) {
      console.error("E20063", error);
      if (error.response) {
        return error.response;
      }
    });
};
