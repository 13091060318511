import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/logo.png';
import { Box } from '@material-ui/core';
import ProgressBar from './ProgressBar';
import LanguageSwitcher from './LanguageSwitcher';
import { Link, useLocation } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  //Customization
  appBarRoot: {
    boxShadow: 'none',
    borderBottom: '1px solid #D8D8D8',
  },
}));

export default function Navbar({ next, factor, lang, setLanguage }) {
  const classes = useStyles();
  let location = useLocation();
  React.useEffect(() => {}, [location]);
  return (
    <div className={classes.root}>
      <AppBar
        position={location.pathname === '/' ? 'fixed' : 'static'}
        color="default"
        classes={{
          root: classes.appBarRoot,
        }}
      >
        <Toolbar>
          <Box className={classes.logo}>
            <Box style={{ marginRight: '1rem' }}>
              <Link to="/">
                <img src={logo} style={{ objectFit: 'contain' }} alt="logo" />
              </Link>
            </Box>{' '}
            <Typography variant="h6" color={'primary'} gutterBottom>
              {' '}
              Onboarding
            </Typography>
          </Box>
          {lang !== null && (
            <LanguageSwitcher lang={lang} setLanguage={setLanguage} />
          )}
        </Toolbar>
        {location.pathname === '/' && (
          <ProgressBar next={next} factor={factor} />
        )}
      </AppBar>
    </div>
  );
}
