import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";

const ProductLineSelect = ({
  state,
  setState,
  next,
  setNext,
  lang,
  question,
}) => {
  const [disable, setDisable] = useState(-1);
  let entry = null;
  let key = question[1]["key"].split(".");
  if (key.length === 3) {
    entry = state[key[0]][key[1]][key[2]];
  } else {
    entry = state[key[0]][key[1]];
  }
  let dep_entry = null;
  let dep_key = question[1]["dependence_key"].split(".");
  if (dep_key.length === 3) {
    dep_entry = state[dep_key[0]][dep_key[1]][dep_key[2]];
  } else {
    dep_entry = state[dep_key[0]][dep_key[1]];
  }
  // useEffect(() => {
  //   if (entry.length === 2) {
  //     setNext(next + 1);
  //   }
  // }, [entry]);
  useEffect(() => {
    if (entry.length === 1) {
      if (question[1]["options"]["en"][dep_entry[0]].includes(entry[0])) {
        setDisable(0);
      } else if (
        question[1]["options"]["en"][dep_entry[1]].includes(entry[0])
      ) {
        setDisable(1);
      } else {
        setDisable(-1);
      }
    } else {
      setDisable(-1);
    }
  }, [entry, dep_entry, question]);
  const handleChange = (value) => {
    if (entry.includes(value) === false) {
      if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: {
              ...state[key[0]][key[1]],
              [key[2]]: [...entry, value],
            },
          },
        });
      } else {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: [...entry, value],
          },
        });
      }

      if (entry.length === 1) {
        setNext(next + 1);
      }
    } else {
      if (["trailer", "truck", "bus", "e_truck", "e_bus"].includes(key[1])) {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: {
              ...state[key[0]][key[1]],
              [key[2]]: entry.filter((item) => item !== value),
            },
          },
        });
      } else {
        setState({
          ...state,
          [key[0]]: {
            ...state[key[0]],
            [key[1]]: entry.filter((item) => item !== value),
          },
        });
      }
    }
  };
  return (
    <Box style={{ margin: "2rem 0rem" }}>
      <Grid container spacing={3}>
        {dep_entry.map((_item, k) => (
          <Grid item lg={6}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",

                margin: "1rem 0rem",
              }}
            >
              <Box style={{ margin: "1rem 0rem" }}>
                <img
                  style={{ maxWidth: 200 }}
                  src={require(`../../../assets/images/${_item}.png`)}
                  alt=""
                />
              </Box>
              {question[1]["options"][lang][_item].map((_subItem, j) => (
                <Box
                  style={{
                    margin: "0.25rem 0rem",
                  }}
                >
                  <Button
                    variant={
                      entry.includes(_subItem) ? "outlined" : "contained"
                    }
                    disableElevation
                    disabled={
                      k === disable
                        ? entry.includes(_subItem) === false
                          ? true
                          : false
                        : false
                    }
                    color="primary"
                    onClick={() => handleChange(_subItem)}
                    style={{
                      textTransform: "unset",
                    }}
                    data-hook={`product-lines-${j}`}
                  >
                    {_subItem}
                  </Button>
                </Box>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductLineSelect;
