import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Drawer,
  Button,
  useTheme,
  Snackbar,
  useMediaQuery,
  Fab,
  Tooltip,
  IconButton,
  Slider,
} from "@material-ui/core";

import questions from "../../assets/questions.json";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelpIcon from "@material-ui/icons/Help";
import { motion } from "framer-motion";
// Question-Types
import IntegerType from "../questions/IntegerType";
import DoubleSelect from "../questions/DoubleSelect";
import SliderType from "../questions/SliderType";
import BooleanType from "../questions/Boolean";
import SingleSelect from "../questions/SingleSelect";
import ProductLineSelect from "../questions/ProductLineSelect";
import SingleSliderType from "../questions/SingleSliderType";
// Forms
import OrgForm from "../StaticForms/OrgForm";
import SignUpForm from "../StaticForms/SignUpForm";
// Nav
import Navbar from "../Navbar";

import lang_config from "../../language";
import TruckDropdown from "../questions/TruckDropdown";
const FormArea = () => {
  const [state, setState] = useState(null);
  const [drawerOpen, setDrawerState] = useState(false);
  const [drawerText, setDrawerText] = useState(null);
  const [lastQues, setLastQues] = useState(-3);
  const [flag, setFlag] = useState(true);
  // eslint-disable-next-line

  const [next, setNext] = useState(-2);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [lang, setLanguage] = useState(null);
  const [operate, setOperate] = useState([]);
  const [singleQues, setSingleQues] = useState([]);
  // eslint-disable-next-line
  const [formQues, setFormQues] = useState(null);
  const [orgData, setOrgData] = useState({
    name: "",
    no_of_yards: "",
    zip_code: "",
    city: "",
    country: "",
  });
  const [factor, setFactor] = useState(0);
  const [success, setSuccess] = useState(false);
  //Savings Estimate Calulation
  const [fuelCost, setFuelCost] = useState(1.5);
  const [avgFuel, setAvgFuel] = useState(30);
  const [fuelSavings, setFuelSavings] = useState(0);
  const [emissons, setEmissons] = useState(0);
  const [adminCosts, setAdminCosts] = useState(0);
  const [tireCosts, setTireCosts] = useState(0);
  const [tco, setTco] = useState(0);
  const [E_BusTire, setE_BusTire] = useState({
    steer_1: "",
    drive_1: "",
    steer_2: "",
    drive_2: "",
  });
  const [E_truckTire, setE_TruckTire] = useState({
    steer_1: "",
    drive_1: "",
    steer_2: "",
    drive_2: "",
  });
  const [busTire, setBusTire] = useState({
    steer_1: "",
    drive_1: "",
    steer_2: "",
    drive_2: "",
  });
  const [truckTire, setTruckTire] = useState({
    steer_1: "",
    drive_1: "",
    steer_2: "",
    drive_2: "",
  });
  const [trailerTire, setTrailerTire] = useState({
    tire_1: "",
    tire_2: "",
  });
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const [notification, setNotification] = useState({
    show: false,
    type: "error",
    message: "An error has occured, Try Again",
  });

  let default_state_values = {
    integer: null,
    "double-select": [],
    dropdown: [],

    "slider-double-integers": 0,
    "slider-single-integer": 0,
    boolean: null,
    "single-select": null,
    "short-text": "",
    "double-select-product-lines": [],
  };
  const handleChangeOperate = (value) => {
    if (operate.includes(value)) {
      setOperate(operate.filter((x) => x !== value));
    } else {
      setOperate([...operate, value]);
    }

    // setNext(next + 1);
  };
  // const [signInMethod, setSignInMethod] = useState('');

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setNotification({
      ...notification,
      show: false,
    });
  };
  const handleChangeLanguage = (value) => {
    setLanguage(value);
    setNext(next + 1);
  };
  useEffect(() => {
    if (
      truckTire.steer_1 !== "" &&
      truckTire.drive_1 !== "" &&
      truckTire.steer_2 !== "" &&
      truckTire.drive_2 !== ""
    ) {
      if (
        [truckTire.steer_1, "Steer", "+", truckTire.drive_1, "Drive"].join(
          " "
        ) !==
        [truckTire.steer_2, "Steer", "+", truckTire.drive_2, "Drive"].join(" ")
      ) {
        setNext(next + 1);
      } else {
        alert(
          "Both Combinations Are Same. \n Select Different Combinations To Continue"
        );
      }
    }
    // eslint-disable-next-line
  }, [truckTire]);
  useEffect(() => {
    if (
      E_truckTire.steer_1 !== "" &&
      E_truckTire.drive_1 !== "" &&
      E_truckTire.steer_2 !== "" &&
      E_truckTire.drive_2 !== ""
    ) {
      if (
        [E_truckTire.steer_1, "Steer", "+", E_truckTire.drive_1, "Drive"].join(
          " "
        ) !==
        [E_truckTire.steer_2, "Steer", "+", E_truckTire.drive_2, "Drive"].join(
          " "
        )
      ) {
        setNext(next + 1);
      } else {
        alert(
          "Both Combinations Are Same. \n Select Different Combinations To Continue"
        );
      }
    }
    // eslint-disable-next-line
  }, [E_truckTire]);
  useEffect(() => {
    if (
      E_BusTire.steer_1 !== "" &&
      E_BusTire.drive_1 !== "" &&
      E_BusTire.steer_2 !== "" &&
      E_BusTire.drive_2 !== ""
    ) {
      if (
        [E_BusTire.steer_1, "Steer", "+", E_BusTire.drive_1, "Drive"].join(
          " "
        ) !==
        [E_BusTire.steer_2, "Steer", "+", E_BusTire.drive_2, "Drive"].join(" ")
      ) {
        setNext(next + 1);
      } else {
        alert(
          "Both Combinations Are Same. \n Select Different Combinations To Continue"
        );
      }
    }
    // eslint-disable-next-line
  }, [E_BusTire]);
  useEffect(() => {
    if (
      busTire.steer_1 !== "" &&
      busTire.drive_1 !== "" &&
      busTire.steer_2 !== "" &&
      busTire.drive_2 !== ""
    ) {
      if (
        [busTire.steer_1, "Steer", "+", busTire.drive_1, "Drive"].join(" ") !==
        [busTire.steer_2, "Steer", "+", busTire.drive_2, "Drive"].join(" ")
      ) {
        setNext(next + 1);
      } else {
        alert(
          "Both Combinations Are Same. \n Select Different Combinations To Continue"
        );
      }
    }
    // eslint-disable-next-line
  }, [busTire]);
  useEffect(() => {
    if (trailerTire.tire_1 !== "" && trailerTire.tire_2 !== "") {
      setNext(next + 1);
    }
    // eslint-disable-next-line
  }, [trailerTire]);
  // Dynamic State Formation And Question Array Formation
  useEffect(() => {
    if (operate.length) {
      const new_state = new Map();
      const form_ques = new Map();
      let single_ques = [];
      let arr = Object.entries(questions);
      // console.log(arr, 'questions');
      for (let i = 0; i < arr.length; i++) {
        let temp = new Map();
        if (questions[arr[i][0]]["is_both_same"]) {
          let ques_obj = questions[arr[i][0]]["questions"];
          let ques_arr = Object.keys(ques_obj);
          if (questions[arr[i][0]]["question_style"] === "individual") {
            single_ques = single_ques.concat(
              Object.entries(ques_obj).map((x) => {
                let new_arr = [];
                new_arr[0] = x[0];
                new_arr[1] = { ...x[1], key: `${arr[i][0]}.${x[0]}` };
                return new_arr;
              })
            );
          } else {
            form_ques.set(arr[i][0], Object.entries(ques_obj));
          }

          // console.log(ques_arr);
          for (let j = 0; j < ques_arr.length; j++) {
            temp.set(
              ques_arr[j],
              default_state_values[
                questions[arr[i][0]]["questions"][ques_arr[j]]["question_type"]
              ]
            );
          }
          new_state.set(arr[i][0], Object.fromEntries(temp));
        } else {
          let sub_map = new Map();
          // eslint-disable-next-line
          operate.map((item) => {
            let ques_obj = questions[arr[i][0]]["questions"][item];
            let ques_arr = Object.keys(ques_obj);
            if (questions[arr[i][0]]["question_style"] === "individual") {
              // if (operate === "both") {
              //   single_ques = single_ques.concat(
              //     Object.entries(ques_obj).map((x) => {
              //       let new_arr = [];
              //       new_arr[0] = x[0];
              //       new_arr[1] = {
              //         ...x[1],
              //         key: `${arr[i][0]}.${item}.${x[0]}`,
              //       };
              //       return new_arr;
              //     })
              //   );
              // } else if (operate === item) {
              //   single_ques = single_ques.concat(
              //     Object.entries(ques_obj).map((x) => {
              //       let new_arr = [];
              //       new_arr[0] = x[0];
              //       new_arr[1] = {
              //         ...x[1],
              //         key: `${arr[i][0]}.${item}.${x[0]}`,
              //       };
              //       return new_arr;
              //     })
              //   );
              // }

              single_ques = single_ques.concat(
                Object.entries(ques_obj).map((x) => {
                  let new_arr = [];
                  new_arr[0] = x[0];
                  new_arr[1] = {
                    ...x[1],
                    key: `${arr[i][0]}.${item}.${x[0]}`,
                  };
                  return new_arr;
                })
              );
            } else {
              form_ques.set(arr[i][0], Object.entries(ques_obj));
            }

            // console.log(ques_arr);
            for (let j = 0; j < ques_arr.length; j++) {
              sub_map.set(
                ques_arr[j],
                default_state_values[
                  questions[arr[i][0]]["questions"][item][ques_arr[j]][
                    "question_type"
                  ]
                ]
              );
            }
            temp.set(item, Object.fromEntries(sub_map));
          });
          new_state.set(arr[i][0], Object.fromEntries(temp));
        }
      }

      // console.log(Object.fromEntries(new_state), "new_state");
      setState(Object.fromEntries(new_state));
      // console.log(single_ques, "single_ques");
      setSingleQues(single_ques);
      setFactor(100 / (single_ques.length + 4));
      setFormQues(Object.fromEntries(form_ques));
    }
    // eslint-disable-next-line
  }, [operate]);
  //Estimate Calculation
  const calcEstimate = () => {
    var units = 0;
    var km = 0;

    if (operate.includes("truck")) {
      units += state["general_configs"]["truck"].no_of_units;
      km += state["general_configs"]["truck"].km_driven_per_year;
    }
    if (operate.includes("bus")) {
      units += state["general_configs"]["bus"].no_of_units;
      km += state["general_configs"]["bus"].km_driven_per_year;
    }
    if (operate.includes("e_truck")) {
      units += state["general_configs"]["e_truck"].no_of_units;
      km += state["general_configs"]["e_truck"].km_driven_per_year;
    }
    if (operate.includes("e_bus")) {
      units += state["general_configs"]["e_bus"].no_of_units;
      km += state["general_configs"]["e_bus"].km_driven_per_year;
    }

    const avg_fuel_consumption = 100 / avgFuel;
    const fuel_cost = fuelCost;
    const co2_per_km = 2.64;
    const total_liters_burnt = (units * km) / avg_fuel_consumption;
    const max_fuel_savings_potential = total_liters_burnt * fuel_cost * 0.1;
    const max_co2_savings_potential =
      (total_liters_burnt * co2_per_km * 0.1) / 1000;
    const max_admin_cost_savings_potential =
      (total_liters_burnt * fuel_cost * 0.05 * 0.04) / 0.4;
    const max_tire_cost_savings_potential =
      (total_liters_burnt * fuel_cost * 0.05 * 0.1) / 0.4;
    const tco_max =
      max_fuel_savings_potential +
      max_admin_cost_savings_potential +
      max_tire_cost_savings_potential;

    setFuelSavings(max_fuel_savings_potential);
    setEmissons(max_co2_savings_potential);
    setAdminCosts(max_admin_cost_savings_potential);
    setTireCosts(max_tire_cost_savings_potential);
    setTco(tco_max);
  };
  useEffect(() => {
    if (state !== null) {
      setTimeout(() => calcEstimate(), 100);
    }
    /*eslint-disable */
  }, [operate, fuelCost, avgFuel]);

  const bigNumFormating = (val) => {
    if (val > Math.pow(10, 20)) {
      return `${Number(val / Math.pow(10, 20)).toFixed(2)} * 10^20`;
    }
    return Number(val).toFixed(2);
  };

  //Console-logging
  // useEffect(() => {
  //   console.log(singleQues, "question");
  //   console.log(formQues, "question");
  // }, [singleQues, formQues]);

  // let nav_value = next * factor;
  return (
    <Box>
      <Navbar
        next={next}
        factor={factor}
        lang={lang}
        setLanguage={setLanguage}
      />
      <Grid
        container
        spacing={0}
        style={{ minHeight: "100vh", scrollBehavior: "" }}
      >
        <Grid item lg={2} md={2} sm={12} xs={12}>
          {!mobile && (
            <Box
              style={{
                display: "flex",
                padding: "0rem 4rem",
                backgroundColor: "#fff",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                borderRight: "1px solid #D8D8D8",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  color={next < singleQues.length ? "primary" : "textSecondary"}
                  gutterBottom
                >
                  {lang === "en"
                    ? "Current Tire Management"
                    : lang === "de"
                    ? "Aktuelles Reifenmanagement"
                    : "Current Tire Management"}
                </Typography>
                <Typography
                  variant="h6"
                  color={
                    next == singleQues.length ? "primary" : "textSecondary"
                  }
                  gutterBottom
                >
                  {lang === "en"
                    ? "Organization Info"
                    : lang === "de"
                    ? "Organizationsinfo"
                    : "Organization Info"}
                </Typography>
                <Typography
                  variant="h6"
                  color={
                    next == singleQues.length + 1 ? "primary" : "textSecondary"
                  }
                  gutterBottom
                >
                  {lang === "en"
                    ? "User Info"
                    : lang === "de"
                    ? "Benutzerinfo"
                    : "User Info"}
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item lg={10} md={10} sm={12} xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              // transition={{ type: 'inertia', velocity: 80 }}
              transition={{ type: "spring", duration: 1 }}
              key={next}
            >
              {next === -2 ? (
                <Box
                  style={{
                    maxWidth: 700,
                  }}
                  mx="1rem"
                >
                  <Typography
                    variant={!mobile ? "h4" : "h5"}
                    style={{ fontWeight: 900 }}
                    gutterBottom
                    data-hook="language-label"
                  >
                    Choose your language{" "}
                  </Typography>

                  <Box style={{ margin: "2rem 0rem" }}>
                    <Box style={{ margin: "0.5rem 0rem" }}>
                      <Button
                        variant={lang === "en" ? "outlined" : "contained"}
                        disableElevation
                        color="primary"
                        onClick={() => handleChangeLanguage("en")}
                        data-hook="choose-lang-button"
                        style={{ textTransform: "unset" }}
                      >
                        English
                      </Button>
                    </Box>
                    <Box style={{ margin: "0.5rem 0rem" }}>
                      <Button
                        variant={lang === "de" ? "outlined" : "contained"}
                        disableElevation
                        color="primary"
                        onClick={() => handleChangeLanguage("de")}
                        style={{ textTransform: "unset" }}
                      >
                        German
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : next === -1 ? (
                <Box style={{ maxWidth: 700 }} mx="1rem">
                  <Typography
                    variant={!mobile ? "h4" : "h5"}
                    style={{ fontWeight: 900 }}
                    gutterBottom
                    data-hook="operate-label"
                  >
                    {lang === "en"
                      ? "Do you operate"
                      : "Welche Eigenschaften hat Ihr Fuhrpark?"}
                  </Typography>

                  <Box style={{ margin: "2rem 0rem" }}>
                    <Box style={{ margin: "0.5rem 0rem" }}>
                      <Button
                        variant={
                          operate.includes("truck") ? "outlined" : "contained"
                        }
                        disableElevation
                        color="primary"
                        onClick={() => handleChangeOperate("truck")}
                        style={{ textTransform: "unset" }}
                        data-hook="select-truck-button"
                      >
                        {lang === "en" ? "Trucks/Vans" : "LKW"}
                      </Button>
                    </Box>
                    <Box style={{ margin: "0.5rem 0rem" }}>
                      <Button
                        variant={
                          operate.includes("trailer") ? "outlined" : "contained"
                        }
                        disableElevation
                        color="primary"
                        onClick={() => handleChangeOperate("trailer")}
                        style={{ textTransform: "unset" }}
                        data-hook="select-trailer-button"
                      >
                        {lang === "en" ? "Trailers" : "Trailer"}
                      </Button>
                    </Box>
                    <Box style={{ margin: "0.5rem 0rem" }}>
                      <Button
                        variant={
                          operate.includes("bus") ? "outlined" : "contained"
                        }
                        disableElevation
                        color="primary"
                        onClick={() => handleChangeOperate("bus")}
                        style={{ textTransform: "unset" }}
                        data-hook="select-bus-button"
                      >
                        {lang === "en" ? "Buses" : "Buses"}
                      </Button>
                    </Box>
                    <Button
                      variant={
                        operate.includes("e_truck") ? "outlined" : "contained"
                      }
                      disableElevation
                      color="primary"
                      onClick={() => handleChangeOperate("e_truck")}
                      style={{ textTransform: "unset" }}
                      data-hook="select-truck-button"
                    >
                      {lang === "en" ? "E-Truck" : "E-LKW"}
                    </Button>
                    <Box style={{ margin: "0.5rem 0rem" }}>
                      <Button
                        variant={
                          operate.includes("e_bus") ? "outlined" : "contained"
                        }
                        disableElevation
                        color="primary"
                        onClick={() => handleChangeOperate("e_bus")}
                        style={{ textTransform: "unset" }}
                        data-hook="select-bus-button"
                      >
                        {lang === "en" ? "E-Buses" : "Buses"}
                      </Button>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                        margin: "1rem 0rem",
                      }}
                    >
                      <Button
                        style={{
                          justifyContent: "flex-end",
                        }}
                        disabled={operate.length === 0}
                        variant="contained"
                        color="primary"
                        data-hook="next-button"
                        onClick={() => {
                          setNext(next + 1);
                        }}
                      >
                        {lang_config[lang].buttons.next}
                      </Button>
                    </Box>
                    {/* <Box style={{ margin: "0.5rem 0rem" }}>
                      <Button
                        variant={operate === "both" ? "outlined" : "contained"}
                        disableElevation
                        color="primary"
                        onClick={() => handleChangeOperate("both")}
                        style={{ textTransform: "unset" }}
                      >
                        {lang === "en" ? " Both" : "Beides"}
                      </Button>
                    </Box> */}
                  </Box>
                </Box>
              ) : (
                <>
                  {singleQues.length !== 0 && (
                    <>
                      {next >= 0 && next < singleQues.length ? (
                        <Box
                          style={{
                            maxWidth: 700,
                          }}
                          mx="1rem"
                        >
                          <Typography
                            variant={!mobile ? "h4" : "h5"}
                            style={{ fontWeight: 900 }}
                            gutterBottom
                            data-hook="truck-operate-question-label"
                          >
                            {singleQues[next][1]["question_phrase"][lang]}{" "}
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setDrawerState(true);
                                setDrawerText(
                                  singleQues[next][1]["help_text"][lang]
                                );
                              }}
                            >
                              <HelpIcon />
                            </IconButton>
                          </Typography>
                          <>
                            {singleQues[next][1]["question_type"] ===
                            "integer" ? (
                              <IntegerType
                                state={state}
                                setState={setState}
                                next={next}
                                setNext={setNext}
                                question={singleQues[next]}
                                lang={lang}
                                data-hook="question-1"
                              />
                            ) : singleQues[next][1]["question_type"] ===
                              "double-select" ? (
                              <DoubleSelect
                                state={state}
                                truckTire={truckTire}
                                setTruckTire={setTruckTire}
                                trailerTire={trailerTire}
                                setTrailerTire={setTrailerTire}
                                setState={setState}
                                next={next}
                                setNext={setNext}
                                question={singleQues[next]}
                                lang={lang}
                              />
                            ) : singleQues[next][1]["question_type"] ===
                              "dropdown" ? (
                              <TruckDropdown
                                state={state}
                                e_truck={E_truckTire}
                                setE_TruckTire={setE_TruckTire}
                                e_bus={E_BusTire}
                                setE_BusTire={setE_BusTire}
                                truckTire={truckTire}
                                busTire={busTire}
                                setBusTire={setBusTire}
                                setTruckTire={setTruckTire}
                                trailerTire={trailerTire}
                                setTrailerTire={setTrailerTire}
                                setState={setState}
                                next={next}
                                setNext={setNext}
                                question={singleQues[next]}
                                lang={lang}
                              />
                            ) : singleQues[next][1]["question_type"] ===
                              "slider-double-integers" ? (
                              <SliderType
                                truckTire={truckTire}
                                trailerTire={trailerTire}
                                state={state}
                                setState={setState}
                                next={next}
                                setNext={setNext}
                                question={singleQues[next]}
                                lang={lang}
                              />
                            ) : singleQues[next][1]["question_type"] ===
                              "boolean" ? (
                              <BooleanType
                                state={state}
                                setState={setState}
                                next={next}
                                setNext={setNext}
                                question={singleQues[next]}
                                lang={lang}
                              />
                            ) : singleQues[next][1]["question_type"] ===
                              "single-select" ? (
                              <>
                                <SingleSelect
                                  state={state}
                                  setState={setState}
                                  next={next}
                                  setNext={setNext}
                                  question={singleQues[next]}
                                  lang={lang}
                                />
                              </>
                            ) : singleQues[next][1]["question_type"] ===
                              "double-select-product-lines" ? (
                              <ProductLineSelect
                                state={state}
                                setState={setState}
                                next={next}
                                setNext={setNext}
                                question={singleQues[next]}
                                lang={lang}
                              />
                            ) : singleQues[next][1]["question_type"] ===
                              "slider-single-integer" ? (
                              <SingleSliderType
                                state={state}
                                setState={setState}
                                next={next}
                                setNext={setNext}
                                question={singleQues[next]}
                                lang={lang}
                                data-hook="range-slider"
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        </Box>
                      ) : next == singleQues.length ? (
                        <>
                          <OrgForm
                            questions={questions}
                            setOrgData={setOrgData}
                            orgData={orgData}
                            next={next}
                            setNext={setNext}
                            lang={lang}
                          />
                        </>
                      ) : next == singleQues.length + 1 ? (
                        <SignUpForm
                          e_truck={E_truckTire}
                          e_bus={E_BusTire}
                          busTire={busTire}
                          truckTire={truckTire}
                          trailerTire={trailerTire}
                          state={state}
                          next={next}
                          setNext={setNext}
                          setSuccess={setSuccess}
                          operate={operate}
                          orgData={orgData}
                          lang={lang}
                          setNotification={setNotification}
                          calcEstimate={calcEstimate}
                        />
                      ) : (
                        <Box>
                          {success && (
                            <>
                              <Box
                                style={{
                                  maxWidth: 700,

                                  width: "100%",
                                }}
                                mx="1rem"
                              >
                                <Typography
                                  variant={!mobile ? "h6" : "h6"}
                                  style={{ fontWeight: 900 }}
                                  color="primary"
                                  gutterBottom
                                  align="left"
                                >
                                  {lang === "en"
                                    ? "Congratulations "
                                    : "Glückwunsch "}
                                  !
                                </Typography>
                                <Typography
                                  variant={!mobile ? "h6" : "h6"}
                                  style={{ fontWeight: 900 }}
                                  color="primary"
                                  gutterBottom
                                  align="left"
                                >
                                  {lang === "en"
                                    ? "The digital twin of your tire management is now setup."
                                    : "Der digitale Zwilling ihres Fuhrparks ist ."}
                                </Typography>
                                <Box
                                  style={{ margin: "0.5rem 0rem" }}
                                  mx="1rem"
                                >
                                  <Typography
                                    variant={!mobile ? "h6" : "h6"}
                                    style={{ fontWeight: 900 }}
                                    color="primary"
                                    gutterBottom
                                    align="left"
                                  >
                                    {lang === "en"
                                      ? " Please check your registered email's inbox to verify your account"
                                      : "Bitte überprüfen Sie ihren Email-Postfach, um die Registrierung abzuschließen."}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{ margin: "0.5rem 0rem" }}
                                  mx="1rem"
                                >
                                  <Typography
                                    variant={!mobile ? "h6" : "h6"}
                                    style={{ fontWeight: 900 }}
                                    gutterBottom
                                    align="left"
                                  >
                                    {lang === "en"
                                      ? "Total Savings Potential Estimated"
                                      : "Vorraussichtliches Einsparpotential"}
                                  </Typography>
                                </Box>

                                <Grid container spacing={3}>
                                  <Grid item lg={8}>
                                    <Grid container spacing={0}>
                                      <Grid item lg={8}>
                                        <Typography
                                          variant={!mobile ? "h6" : "h6"}
                                          gutterBottom
                                          align="left"
                                        >
                                          {lang === "en"
                                            ? "Fuel Costs"
                                            : "Vorraussichtliches Einsparpotential"}
                                        </Typography>
                                        <Typography
                                          variant={!mobile ? "h6" : "h6"}
                                          gutterBottom
                                          align="left"
                                        >
                                          {lang === "en"
                                            ? "CO2 Emissions"
                                            : "Reduzierung Dieselkosten"}
                                        </Typography>
                                        <Typography
                                          variant={!mobile ? "h6" : "h6"}
                                          gutterBottom
                                          align="left"
                                        >
                                          {lang === "en"
                                            ? "Admin Costs"
                                            : "Reduzierung Administrationskosten"}
                                        </Typography>
                                        <Typography
                                          variant={!mobile ? "h6" : "h6"}
                                          gutterBottom
                                          align="left"
                                        >
                                          {lang === "en"
                                            ? "Tire Costs"
                                            : "Reduzierung Reifenkosten"}
                                        </Typography>
                                        <Typography
                                          variant={!mobile ? "h6" : "h6"}
                                          gutterBottom
                                          align="left"
                                        >
                                          {lang === "en"
                                            ? "Total Cost of Ownership"
                                            : "Reduzierung Gesamtbetriebskosten"}
                                        </Typography>
                                      </Grid>
                                      <Grid item lg={4}>
                                        <Typography
                                          variant={
                                            !mobile ? "subtitle1" : "subtitle1"
                                          }
                                          gutterBottom
                                          align="left"
                                        >
                                          =&nbsp;
                                          {bigNumFormating(fuelSavings)}
                                          &euro;
                                        </Typography>

                                        <Typography
                                          variant={
                                            !mobile ? "subtitle1" : "subtitle1"
                                          }
                                          gutterBottom
                                          align="left"
                                        >
                                          =&nbsp; {bigNumFormating(emissons)}{" "}
                                          Tonne
                                        </Typography>
                                        <Typography
                                          variant={
                                            !mobile ? "subtitle1" : "subtitle1"
                                          }
                                          gutterBottom
                                          align="left"
                                        >
                                          =&nbsp;
                                          {bigNumFormating(adminCosts)} &euro;
                                        </Typography>
                                        <Typography
                                          variant={
                                            !mobile ? "subtitle1" : "subtitle1"
                                          }
                                          gutterBottom
                                          align="left"
                                        >
                                          =&nbsp;
                                          {bigNumFormating(tireCosts)} &euro;
                                        </Typography>
                                        <Typography
                                          variant={
                                            !mobile ? "subtitle1" : "subtitle1"
                                          }
                                          gutterBottom
                                          align="left"
                                        >
                                          =&nbsp;{bigNumFormating(tco)} &euro;
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item lg={4}>
                                    <Box>
                                      <Typography variant="h6" gutterBottom>
                                        {lang === "en"
                                          ? "Fuel Cost in €/l"
                                          : "Dieselkosten in € pro Liter"}
                                      </Typography>

                                      <Box style={{ margin: "2rem 0rem" }}>
                                        <Slider
                                          value={fuelCost}
                                          onChange={(_, val) =>
                                            setFuelCost(val)
                                          }
                                          aria-labelledby="continuous-slider"
                                          valueLabelDisplay="on"
                                          step={0.1}
                                          min={1}
                                          max={2}
                                        />
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Typography variant="h6" gutterBottom>
                                        {lang === "en"
                                          ? "Fuel Consumption in l/100 Km"
                                          : "Durchschnittsverbrauch in Liter pro 100km"}
                                      </Typography>
                                      <Box style={{ margin: "2rem 0rem" }}>
                                        <Slider
                                          value={avgFuel}
                                          onChange={(_, val) => setAvgFuel(val)}
                                          aria-labelledby="continuous-slider"
                                          valueLabelDisplay="on"
                                          step={1}
                                          min={25}
                                          max={45}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Box
                                  width="100%"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <a
                                    href={redirect_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button
                                      color={"primary"}
                                      variant={"contained"}
                                      style={{
                                        textTransform: "unset",
                                        boxShadow: "none",
                                      }}
                                    >
                                      <Typography
                                        variant={!mobile ? "h6" : "subtitle1"}
                                        align="center"
                                      >
                                        {
                                          lang_config[lang].buttons
                                            .go_to_sign_in
                                        }
                                      </Typography>
                                    </Button>
                                  </a>
                                </Box>
                              </Box>
                            </>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}
            </motion.div>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ marginTop: "-10rem", padding: "0rem 5rem" }}
          >
            <Box>
              <Tooltip
                title="Press to go back"
                aria-label="add"
                placement="top"
                arrow
              >
                <Fab
                  color="primary"
                  disabled={next === -2 ? true : false}
                  aria-label="add"
                  onClick={() => {
                    setNext(next - 1);
                    if (flag) {
                      setLastQues(next);
                      setFlag(false);
                    }
                  }}
                >
                  <ArrowBackIcon />
                </Fab>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip
                title="Press to go forward"
                aria-label="add"
                placement="top"
                arrow
              >
                <Fab
                  color="primary"
                  style={{ display: next < lastQues ? "unset" : "none" }}
                  // disabled={next < lastQues ? false : true}
                  aria-label="add"
                  onClick={() => {
                    if (next + 1 == lastQues) {
                      setFlag(true);
                    }
                    setNext(next + 1);
                  }}
                >
                  <ArrowForwardIcon />
                </Fab>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={notification.show}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={notification.type}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      <Drawer
        anchor={"right"}
        open={drawerOpen}
        onClose={() => {
          setDrawerState(false);
        }}
      >
        <Box
          style={{
            maxWidth: "500px",
            background: "#EDEDED",
            height: "100%",
            padding: "2rem 1rem",
          }}
        >
          <Typography
            variant={!mobile ? "h5" : "h6"}
            style={{ fontWeight: 900 }}
            gutterBottom
          >
            {drawerText !== null && drawerText}
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
};

export default FormArea;
