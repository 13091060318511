import React from 'react';
import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import lang_config from '../../language';

const PasswordComplexityDepictor = ({ password, lang }) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      border='1px solid grey'
      width='230px'
      borderRadius={4}
      p={1}
      position='absolute'
      top={0}
      right={-235}
    >
      <Box fontWeight={700}> The password must have:</Box>
      {Object.keys(password).map((key, i) => (
        <SecondaryText text={lang_config[lang]['passwordComplexity'][key]} success={password[key]} key={i} />
      ))}
    </Box>
  );
};

const SecondaryText = ({ success, text }) => {
  return (
    <Box
      display='flex'
      width='100%'
      height='16px'
      alignItems={'center'}
      fontSize='12px'
      color={success ? 'green' : 'red'}
    >
      <Box display='flex' alignItems={'center'} mr={1}>
        {success ? <CheckIcon /> : <ClearIcon />}
      </Box>
      <Box display='flex' alignItems={'center'}>
        {text}
      </Box>
    </Box>
  );
};

export default PasswordComplexityDepictor;
