export const tire_sizes = [
  "205/65R17.5",
  "215/75R17.5",
  "235/75R17.5",
  "245/70R17.5",
  "295/55R22.5",
  "295/60R22.5",
  "315/45R22.5",
  "315/60R22.5",
  "315/70R22.5",
  "315/80R22.5",
  "355/50R22.5",
  "385/55R19.5",
  "385/55R22.5",
  "385/65R22.5",
  "385/65R22.5",
  "435/50R19.5",
  "445/45R19.5",
];
