import { Box, CardContent, Card, Typography, Button } from "@material-ui/core";
import maintence_logo from "../assets/images/maintenance.png";

const Maintenance = () => {
  return (
    <Box
      style={{
        width: "100vw",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "#E5E5E5",
        padding: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card style={{ maxWidth: 600, width: "100%", padding: "1rem 3rem" }}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={maintence_logo} alt="maintence_logo" />
          <Box my="1rem">
            <Typography
              variant="h5"
              gutterBottom
              align="center"
              style={{
                color: "red",
              }}
              fontWeight="bold"
            >
              Unter Wartung
            </Typography>
            <Typography variant="h6" align="center" fontWeight="bold">
              Unsere Techniker führen derzeit planmäßige Wartungsarbeiten am
              Armaturenbrett durch.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              fontWeight="bold"
            >
              Entschuldigung für die Unannehmlichkeiten.
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.reload()}
            style={{ textTransform: "unset" }}
          >
            Try Again
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Maintenance;
