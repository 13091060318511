export const en = {
  form: {
    email: {
      invalid: "Invalid Email",
      emailTaken: "Account already created with the same email",
      emailAvailable: "Email Available",
    },
    fields: {
      email: "Email",
      first_name: "First Name",
      last_name: "Last Name",
      password: "Password",
      position: "Position",
    },
  },
  integer_type: {
    sub_text: "Press Enter To Continue",
  },
  buttons: {
    next: "Next",
    sign_up: "SignUp",
    go_to_sign_in: "Go To SignIn",
  },
  links: {
    terms_and_conditions: "Terms&Conditions",
    privacy_policy: "PrivacyPolicy",
    and: "and",
  },
  passwordComplexity: {
    number: "At least one number",
    specialCharacter: "At least one special character",
    lowercase: "At least one lowercase letter",
    uppercase: "At least one uppercase letter",
    length: "At least 8 characters",
  },
  maintencePage: {
    title: "Under Maintenance",
    description:
      "Our Engineers are currently performing scheduled maintence on dashboard.",
    apology: "Sorry for inconvenience.",
  },
};
